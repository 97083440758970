import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  TextField,
  Grid,
  Button,
  InputAdornment,
  Box,
  Container,
} from '@mui/material';
import { Admins, ExtendedAdmin } from '../../../interfaces';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { updateAdmin } from '../../../services/api/admin';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface AdminEditFormProps {
  adminId: string;
  adminEdit: ExtendedAdmin;
  closeEvent: () => void;
}
const MySwal = withReactContent(Swal);

export const AdminEditForm: React.FC<AdminEditFormProps> = ({
  adminId,
  adminEdit,
  closeEvent,
}) => {
  const [editedAdmins, setEditedAdmins] = useState<ExtendedAdmin>(adminEdit);
  const [editShowPassword, setEditShowPassword] = useState(false);
  const handleEditClick = (admin: Admins) => {
    setEditedAdmins(admin);
    setEditShowErrors({
      email: false,
      role: false,
      fullName: false,
      password: false,
    });
  };
  const [editShowErrors, setEditShowErrors] = useState({
    email: false,
    role: false,
    fullName: false,
    password: false,
  });

  const roles = ['admin', 'superAdmin'];
  const handleSaveEdit = async () => {
    // Validación específica para el campo "Full Name"
    if (!editedAdmins?.fullName?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: false,
      }));
    }

    // Validación específica para el campo "Email"
    if (!editedAdmins?.email) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: false,
      }));
    }

    // Validación específica para el campo "Role"
    if (!editedAdmins?.role) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        role: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        role: false,
      }));
    }

    // Validación específica para el campo "Password"
    if (!editedAdmins?.password || !editedAdmins) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        pas: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        pas: false,
      }));
    }

    // Validación general de todos los campos
    if (
      !editedAdmins?.fullName ||
      !editedAdmins?.email ||
      !editedAdmins?.role ||
      !editedAdmins?.password
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title:
          'Se ha editado correctamente la información de ' +
          (editedAdmins?.fullName || ''),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
          confirmButton: 'swal2-custom-button',
        },
      });

      const updatedConfig = await updateAdmin(editedAdmins);
      setEditedAdmins(updatedConfig);
      //tableProps.refetch();
      window.location.reload();
      console.log(
        'Cambios guardados exitosamente. Datos actualizados:',
        updatedConfig
      );
    } catch (error) {
      console.error('Error al guardar la edición de la membresía:', error);
    }
  };

  // Función para renderizar el formulario de edición
  const renderEditForm = () => (
    <form style={formStyle}>
      <TextField
        type="text"
        name="fullName"
        required
        value={editedAdmins?.fullName || ''}
        onChange={(e) =>
          setEditedAdmins({
            ...editedAdmins!,
            fullName: e.target.value,
          })
        }
        error={editShowErrors.fullName}
        helperText={editShowErrors.fullName ? 'Campo requerido' : ''}
        sx={textFieldStyle}
      />

      {/* email */}
      <TextField
        type="string"
        name="email"
        required
        value={editedAdmins?.email?.toString() || ''}
        onChange={(e) =>
          setEditedAdmins({
            ...editedAdmins!,
            email: e.target.value,
          })
        }
        error={editShowErrors.email}
        helperText={editShowErrors.email ? 'Campo requerido' : ''}
        sx={textFieldStyle}
      />

      {/* contraseña */}
      <TextField
        type={editShowPassword ? 'text' : 'password'}
        name="password"
        placeholder="Contraseña"
        required
        value={editedAdmins?.password?.toString() || ''}
        onChange={(e) =>
          setEditedAdmins({
            ...editedAdmins!,
            password: e.target.value,
          })
        }
        error={editShowErrors.password}
        helperText={editShowErrors.password ? 'Campo requerido' : ''}
        sx={textFieldStyle}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setEditShowPassword(!editShowPassword)}
                edge="end"
              >
                {editShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* rol */}
      <FormControl fullWidth sx={textFieldStyle}>
        <Select
          labelId="adminRole"
          label="Rol del Administrador"
          id="admin-role"
          value={editedAdmins?.role || ''}
          onChange={(e) =>
            setEditedAdmins({
              ...editedAdmins!,
              role: e.target.value as string,
            })
          }
          inputProps={{
            name: 'adminRole',
            id: 'admin-role',
          }}
        >
          <MenuItem disabled value="">
            Elige o selecciona un rol
          </MenuItem>
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              sx={buttonGuardarStyle}
              title="Guardar"
              onClick={handleSaveEdit}
            >
              <Typography sx={buttonTypographyStyle}>{'GUARDAR'}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button sx={buttonCancelarStyle} onClick={closeEvent}>
              <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );

  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Administrador
          </Typography>
        </Box>
      </div>
      <Box sx={{ m: 2 }} />

      <IconButton sx={closIconStyle} onClick={closeEvent}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#F6F6F6',
          width: '500px',
          height: '330px',
          marginTop: '20px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          {renderEditForm()}
        </Container>
      </div>
    </>
  );
};
const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const formStyle = {
  width: '100%',
  marginTop: '20px',
};

const textFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
