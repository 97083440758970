import React, { useState } from 'react';
import {
  Typography,
  TextField,
  IconButton,
  Grid,
  Button,
  Box,
  Tooltip,
  Container,
  Alert,
  AlertTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { updateMemberships } from '../../../services/api/memberships';
import { Memberships, ExtendedMembership } from '../../../interfaces';
import { InputAdornment } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FormControl, Select, MenuItem } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import FormLabel from '@mui/material/FormLabel';
interface MembershipEditModalProps {
  membershipsId: string;
  membershipsEdit: ExtendedMembership;
  closeEvent: () => void;
}
const MySwal = withReactContent(Swal);

export const MembershipEditModal: React.FC<MembershipEditModalProps> = ({
  membershipsId,
  membershipsEdit,
  closeEvent,
}) => {
  const [editedMemberships, setEditedMemberships] =
    useState<ExtendedMembership>(membershipsEdit);
  const handleEditClick = (memberships: Memberships) => {
    setEditedMemberships(memberships);
    setEditShowErrors({
      typeOfMembership: false,
      membershipCost: false,
      amountOfInfluencersPerOffer: false,
      amountOfOffersPerMonth: false,
      influencerFollowers: false,
      amountOfPublicationsPerOffer: false,
    });
  };
  const [editShowErrors, setEditShowErrors] = useState({
    typeOfMembership: false,
    membershipCost: false,
    amountOfInfluencersPerOffer: false,
    amountOfOffersPerMonth: false,
    influencerFollowers: false,
    amountOfPublicationsPerOffer: false,
  });
  const followersHelperText =
    'Formato: 0-30k, 30-70k, 70-150k, 150-500k, 500k-1M, 1M+';
  const typeOfMembership = ['Plata', 'Platino', 'Oro'];
  const handleSaveEdit = async () => {
    // Validación específica para el campo "Tipo de Membresia"
    if (!editedMemberships?.typeOfMembership?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        typeOfMembership: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        typeOfMembership: false,
      }));
    }

    // Validación específica para el campo "Costo"
    if (!editedMemberships?.membershipCost) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        membershipCost: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        membershipCost: false,
      }));
    }

    // Validación específica para el campo "Influencers por Oferta"
    if (!editedMemberships?.amountOfInfluencersPerOffer) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: false,
      }));
    }

    // Validación específica para el campo "Ofertas por Mes"
    if (!editedMemberships?.amountOfOffersPerMonth) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffersPerMonth: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffersPerMonth: false,
      }));
    }

    // Validación específica para el campo "Seguidores del Influencer"
    if (!editedMemberships?.influencerFollowers?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        influencerFollowers: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        influencerFollowers: false,
      }));
    }

    // Validación específica para el campo "Publicaciones por Oferta"
    if (!editedMemberships?.amountOfPublicationsPerOffer) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: false,
      }));
    }

    // Validación general de todos los campos
    if (
      !editedMemberships?.typeOfMembership ||
      !editedMemberships?.membershipCost ||
      !editedMemberships?.amountOfInfluencersPerOffer ||
      !editedMemberships?.amountOfOffersPerMonth ||
      !editedMemberships?.influencerFollowers ||
      !editedMemberships?.amountOfPublicationsPerOffer
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Editado Correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });

      // Lógica para guardar los cambios
      const updatedConfig = await updateMemberships(editedMemberships);
      //setIsEditing(false);
      setEditedMemberships(updatedConfig);
      window.location.reload();
      //tableProps.refetch();
      console.log(
        'Cambios guardados exitosamente. Datos actualizados:',
        updatedConfig
      );
    } catch (error) {
      console.error('Error al guardar la edición de la membresía:', error);
    }
  };
  const renderEditForm = () => (
    <div>
      <form style={formStyle}>
        {/* Tipo de Membresia */}
        <FormLabel
          htmlFor="membershipType"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Tipo de Membresia
        </FormLabel>

        <FormControl fullWidth sx={TextFieldStyle}>
          <Select
            id="membership-type"
            value={editedMemberships?.typeOfMembership || ''}
            onChange={(e) =>
              setEditedMemberships({
                ...editedMemberships!,
                typeOfMembership: e.target.value as string,
              })
            }
            inputProps={{
              name: 'membershipType',
              id: 'membership-type',
            }}
            disabled // Deshabilitar el campo
          >
            {typeOfMembership.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Costo */}
        <FormLabel
          htmlFor="membershipCost"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Costo de la membresia
        </FormLabel>
        <TextField
          name="membershipCost"
          required
          value={editedMemberships?.membershipCost?.toString() || ''}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, '');
            setEditedMemberships({
              ...editedMemberships!,
              membershipCost: inputValue === '' ? 0 : Number(inputValue),
            });
          }}
          error={editShowErrors.membershipCost}
          helperText={editShowErrors.membershipCost ? 'Campo requerido' : ''}
          sx={textFieldCostStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Cantidad de Influencers por Oferta */}
        <FormLabel
          htmlFor="amountOfInfluencersPerOffer"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Influencers por Oferta
        </FormLabel>
        <TextField
          name="amountOfInfluencersPerOffer"
          required
          value={
            editedMemberships?.amountOfInfluencersPerOffer?.toString() || ''
          }
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, '');
            setEditedMemberships({
              ...editedMemberships!,
              amountOfInfluencersPerOffer:
                inputValue === '' ? 0 : Number(inputValue),
            });
          }}
          error={editShowErrors.amountOfInfluencersPerOffer}
          helperText={
            editShowErrors.amountOfInfluencersPerOffer ? 'Campo requerido' : ''
          }
          sx={TextFieldStyle}
        />

        {/* Cantidad de Ofertas por Mes */}
        <FormLabel
          htmlFor="amountOfOffersPerMonth"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Ofertas por Mes
        </FormLabel>
        <TextField
          name="amountOfOffersPerMonth"
          required
          value={editedMemberships?.amountOfOffersPerMonth?.toString() || ''}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, '');
            setEditedMemberships({
              ...editedMemberships!,
              amountOfOffersPerMonth:
                inputValue === '' ? 0 : Number(inputValue),
            });
          }}
          error={editShowErrors.amountOfOffersPerMonth}
          helperText={
            editShowErrors.amountOfOffersPerMonth ? 'Campo requerido' : ''
          }
          sx={TextFieldStyle}
        />

        {/* Seguidores del Influencer */}
        <FormLabel
          htmlFor="influencerFollowers"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Seguidores del Influencer
        </FormLabel>
        <TextField
          type="text"
          name="influencerFollowers"
          required
          value={editedMemberships?.influencerFollowers?.toString() || ''}
          onChange={(e) =>
            setEditedMemberships({
              ...editedMemberships!,
              influencerFollowers: e.target.value,
            })
          }
          error={editShowErrors.influencerFollowers}
          helperText={
            <React.Fragment>
              <span>
                {editShowErrors.influencerFollowers ? 'Campo requerido. ' : ''}
              </span>
            </React.Fragment>
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={followersHelperText} arrow>
                  <InfoIcon style={{ cursor: 'pointer' }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={TextFieldStyle}
        />

        {/* Cantidad de Publicaciones por Oferta */}
        <FormLabel
          htmlFor="amountOfPublicationsPerOffer"
          required
          sx={{
            fontSize: '0.8rem',
          }}
        >
          Publicaciones por Oferta
        </FormLabel>
        <TextField
          name="amountOfPublicationsPerOffer"
          required
          value={
            editedMemberships?.amountOfPublicationsPerOffer?.toString() || ''
          }
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, '');
            setEditedMemberships({
              ...editedMemberships!,
              amountOfPublicationsPerOffer:
                inputValue === '' ? 0 : Number(inputValue),
            });
          }}
          error={editShowErrors.amountOfPublicationsPerOffer}
          helperText={
            editShowErrors.amountOfPublicationsPerOffer ? 'Campo requerido' : ''
          }
          sx={TextFieldStyle}
        />
        <br />
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                sx={buttonGuardarStyle}
                title="Guardar"
                onClick={handleSaveEdit}
              >
                <Typography sx={buttonTypographyStyle}>{'GUARDAR'}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button sx={buttonCancelarStyle} onClick={closeEvent}>
                <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Membresia
          </Typography>
        </Box>
      </div>
      <Box sx={{ m: 2 }} />
      <IconButton sx={closIconStyle} title="Cancelar" onClick={closeEvent}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#F6F6F6',
          width: '500px',
          height: '550px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          {renderEditForm()}
        </Container>
      </div>
    </>
  );
};

const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const formStyle = {
  width: '100%',
};


const textFieldCostStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
const TextFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};