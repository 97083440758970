import { privateAxiosInstance } from "../axiosConfig";
import { Offers, OffersAccepted } from "../../interfaces";

export const getAllOffers = async (
  page = 0,
  OFFERS_PER_PAGE = 0
): Promise<{ offers: Offers[]; maxOffers: number }> => {
  const offset: number = OFFERS_PER_PAGE * page!;
  const response = await privateAxiosInstance.get(
    `/offers/find-all/web/paginated?limit=${OFFERS_PER_PAGE}&offset=${offset}`
  );
  return response.data;
};

export const getAcceptedOffersForInfluencer = async (
  id: string
): Promise<{ acceptedOffers: OffersAccepted[]; maxAcceptedOffers: number }> => {
  const response = await privateAxiosInstance.get(
    `/offers/accepted-offers/influencers/${id}`
  );
  return response.data;
};

export const updateStatus = async (
  acceptedOfferId: string,
  influencerId: string
) => {
  const response = await privateAxiosInstance.get(
    `/offers/influencer-confirm-done-offer/${acceptedOfferId}?influencerId=${influencerId}`
  );

  return response.data;
};

export const influencerRetryOffer = async (
  acceptedOfferId: string,
  influencerId: string
) => {
  const response = await privateAxiosInstance.get(
    `/offers/influencer-retry-offer/${acceptedOfferId}?influencerId=${influencerId}`
  );
  return response.data;
};
