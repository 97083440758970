import React, { useState } from 'react';
import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import {
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Container,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { createAdmins } from '../../../services/api/admin';
import { CreateAdmin } from '../../../interfaces/index';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FormControl, Select, MenuItem } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface AdminFormProps {
  closeEvent: () => void;
}

export const AdminForm: React.FC<AdminFormProps> = ({ closeEvent }) => {
  const MySwal = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');

  const [showErrors, setShowErrors] = useState({
    email: false,
    fullName: false,
    password: false,
    role: false,
  });
  const roles = ['admin', 'superAdmin'];
  const [showPassword, setShowPassword] = useState(false);

  const handleConfirm = async () => {
    if (!email.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        email: false,
      }));
    }

    // Validación específica para el campo "fullName"
    if (!fullName.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: false,
      }));
    }
    // Validación específica para el campo "password"
    if (password.length < 8) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        password: false,
      }));
    }

    // Validación específica para el campo "Rol"
    if (!role.trim()) {
      setShowErrors((prevErrors) => ({ ...prevErrors, role: true }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        role: false,
      }));
    }

    if (!email || !fullName || !password || !role) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos correctamente.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    const formData: CreateAdmin = {
      email,
      fullName,
      password,
      role,
    };
    console.log('FormData:', formData);

    try {
      await createAdmins(formData);
      console.log('Admin creado correctamente');
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Admin creado correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error('Error al crear un admin:', error);
    }
  };
  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Nuevo Administrador
          </Typography>
        </Box>
      </div>

      <Box sx={{ m: 2 }} />

      <IconButton sx={closIconStyle} title="Cancelar" onClick={closeEvent}>
        <CloseOutlinedIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#dddddd4a',
          width: '500px',
          height: '330px',
          marginTop: '20px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          <form style={formStyle}>
            <TextField
              type="text"
              name="email"
              placeholder="Correo Electronico"
              sx={TextFieldStyle}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={showErrors.email}
              helperText={showErrors.email ? 'Campo requerido' : ''}
            />

            <TextField
              placeholder="Nombre del Administrador"
              type="text"
              name="fullName"
              sx={TextFieldStyle}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              error={showErrors.fullName}
              helperText={showErrors.fullName ? 'El nombre es requerido' : ''}
            />

            <TextField
              placeholder="Contraseña"
              type={showPassword ? 'text' : 'password'}
              name="password"
              sx={TextFieldStyle}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={showErrors.password}
              helperText={
                showErrors.password
                  ? 'La contraseña es requerida y tiene que tener 8 caracteres'
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth sx={TextFieldStyle}>
              <Select
                labelId="roleLabel"
                label="Rol"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value as string)}
                inputProps={{
                  name: 'role',
                  id: 'role',
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  Elige o selecciona un rol
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button sx={buttonGuardarStyle} onClick={handleConfirm}>
                    <Typography sx={buttonTypographyStyle}>Guardar</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={buttonCancelarStyle} onClick={closeEvent}>
                    <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const formStyle = {
  width: '100%',
  marginTop: '20px',
};

const TextFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
