import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { TableLoadingOrNoData } from './TableLoadingOrNoData';

interface ICustomTableProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  columns: GridColDef[];
  rows: GridRowsProp;
  actualPage?: number;
  setActualPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage?: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  maxRowsToShow: number;
  setActive?: React.Dispatch<React.SetStateAction<any>>;
  isLoading: boolean;
}

export const CustomTable = ({
  title,
  children,
  columns,
  rows = [],
  actualPage = 0,
  rowsPerPage = 5,
  maxRowsToShow,
  setActualPage,
  setRowsPerPage,
  isLoading,
  setActive,
}: ICustomTableProps) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: rowsPerPage,
    page: actualPage,
  });

  useEffect(() => {
    setActualPage(paginationModel.page);
    setRowsPerPage(paginationModel.pageSize);
  }, [paginationModel, setActualPage, setRowsPerPage]);

  const handleCellClick = (e: any) => {
    if (isLoading) return;
    setActive!(e);
  };

  return (
    <Box
      sx={{
        width: '80%',
        minHeight: '460px',
        backgroundColor: 'white',
        borderRadius: 2,
        padding: 1,
        display: 'flex',
        flexDirection: 'column',
        ':hover': {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <Typography
        className="text-left"
        variant="h6"
        component={'h4'}
        fontSize={{ xs: '1rem', sm: '1.25rem', xl: '1.5rem' }} // Valores más pequeños
        fontWeight={'bold'}
        my={1}
      >
        {title}
      </Typography>

      {children}
      {isLoading || rows.length === 0 ? (
        <TableLoadingOrNoData status={isLoading ? 'loading' : 'noData'} />
      ) : (
        <>
          <DataGrid
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowCount={maxRowsToShow ?? 5}
            rows={rows}
            onCellClick={handleCellClick}
            columns={columns}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            paginationMode="server"
            sx={{
              backgroundColor: 'white',
              flex: 1,
            }}
          />
        </>
      )}
    </Box>
  );
};
