import React from 'react';
import { Avatar } from '@mui/material';

export const TiktokIcon = () => {
  return (
    <Avatar
      alt="LitAdminLogo"
      src={process.env.PUBLIC_URL + '/tik-tok.png'}
      sx={{ width: 25, height: 25 }}
    />
  );
};
