// pages/Offers.tsx
import { useState } from "react";
import { OffersTable } from "./views/OffersTable";
import { useCRUDModal } from "../../hooks";
import { Navigate, Route, Routes } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}



export const Offers = () => {
  const [offers, setOffers] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: "offers/find-all/web/",
    // updateFn: updateInfluencer,
  });
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleCellClick = (event: any) => {
    const { row, field } = event;

    setOffers(row);

    switch (field) {
      case "approve":
        updateMutation.mutate({ ...row, verified: true });
        break;
      case "reject":
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <OffersTable
              offers={offers}
              setOffers={setOffers}
              handleCellClick={handleCellClick}
            />
          }
        />
        <Route path="/*" element={<Navigate to={'/offers'} />} />
      </Routes>
    </>
  );
};
