import { Box, Grid } from '@mui/material';
import { StatCard } from './StatCard';
// icons
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { PeopleOutline } from '@mui/icons-material';

export const List = () => {

  const dashboardData = [
    {
      title: '$45,000',
      subtitle: 'Comercios',
      icon: <ShoppingBagOutlinedIcon sx={{ fontSize: 40, color: '#198754' }} />,
    },
    {
      title: '10',
      subtitle: 'Membresias',
      icon: <VerifiedOutlinedIcon sx={{ fontSize: 40 }} />,
    },
    {
      title: '100',
      subtitle: 'Influencers',
      icon: <ThumbUpAltOutlinedIcon color="info" sx={{ fontSize: 40 }} />,
    },
    {
      title: `10`,
      subtitle: 'Ofertas',
      icon: <LibraryBooksOutlinedIcon color="warning" sx={{ fontSize: 40 }} />,
    },
    
    {
      title: '10',
      subtitle: 'Usuarios',
      icon: <PeopleOutline sx={{ fontSize: 40, color: '#0d6efd' }} />,
    },
  ];

  return (
    <Box
      className="animate__animated animate__fadeIn"
      sx={{ padding: '0px 50px', marginTop: { xs: '60px', md: '0px' } }}
    >
      <Grid container spacing={2}>
        {dashboardData.map((item) => (
          <StatCard
            key={item.subtitle}
            title={item.title}
            subtitle={item.subtitle}
            icon={item.icon}
          />
        ))}
      </Grid>
    </Box>
  );
};
