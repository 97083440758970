import React, { useState, useEffect } from 'react';
import {
  getAcceptedOffersForInfluencer,
  updateStatus,
  influencerRetryOffer,
} from '../../../services/api/offers';
import { OffersAccepted } from '../../../interfaces';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Badge, Typography, IconButton, Grid } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NotificationsIcon from '@mui/icons-material/Notifications';

const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};

interface Props {
  amountOfInfluencersToReviewOffer: number;
  offersId: string;
}

const MySwal = withReactContent(Swal);

export const AcceptOffers = ({
  offersId,
  amountOfInfluencersToReviewOffer,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [acceptedOffers, setAcceptedOffers] = useState<OffersAccepted[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchAcceptedOffers = async () => {
      const result = await getAcceptedOffersForInfluencer(offersId);
      setAcceptedOffers(result.acceptedOffers);
    };
    fetchAcceptedOffers();
  }, [offersId]);

  const statusStyles: Record<
    string,
    { backgroundColor: string; color: string }
  > = {
    'in-review': { backgroundColor: 'lightgreen', color: 'green' },
    inactive: { backgroundColor: 'lightgray', color: 'gray' },
    pending: { backgroundColor: 'lightgray', color: 'gray' },
    suspended: { backgroundColor: 'lightcoral', color: 'red' },
  };

  const renderStatus = ({ value }: GridRenderCellParams) => {
    const style = statusStyles[value] || {};
    return (
      <Box
        sx={{
          ...style,
          padding: '4px 8px',
          borderRadius: '8px',
          textAlign: 'center',
          width: 'fit-content',
          margin: 'auto',
        }}
      >
        <Typography variant="body2">
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </Typography>
      </Box>
    );
  };

  const handleConfirm = async (offersId: string, influencerId: string) => {
    MySwal.fire({
      position: 'center',
      title: 'Canje Finalizado!',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'small-swal-popup',
        container: 'my-swal',
      },
    });
    const updateResult = await updateStatus(offersId, influencerId);
    console.log(updateResult);
    setAcceptedOffers((prevAcceptedOffers) =>
      prevAcceptedOffers.filter((offer) => offer.influencer.id !== influencerId)
    );
  };

  const handleReject = async (offersId: string, influencerId: string) => {
    MySwal.fire({
      icon: 'error',
      position: 'center',
      title: 'El canje sera reintentado por el influencer',
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: 'small-swal-popup',
        container: 'my-swal',
      },
    });
    const result = await influencerRetryOffer(offersId, influencerId);
    console.log(result);
    setAcceptedOffers((prevAcceptedOffers) =>
      prevAcceptedOffers.filter((offer) => offer.influencer.id !== influencerId)
    );
  };

  const handleInfluencersDetailsClick = (row: any) => {
    // Implementa la lógica para mostrar más información del influencer
  };

  const renderActionsCell = ({ row }: GridRenderCellParams) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <IconButton
        sx={iconButtonStyle}
        title="Aceptar"
        onClick={() => handleConfirm(offersId, row.influencer.id)}
      >
        <DoneOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Rechazar"
        onClick={() => handleReject(offersId, row.influencer.id)}
      >
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Estado',
      headerAlign: 'center',
      flex: 1,
      minWidth: 100,
      headerClassName: 'table-header',
      renderCell: renderStatus,
    },
    {
      field: 'influencer',
      headerName: 'Influencer',
      flex: 1,
      minWidth: 100,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          sx={{
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '390px',
            lineHeight: 'normal',
          }}
        >
          {row.influencer.instagram}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 400,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderActionsCell,
    },
  ];

  return (
    <div style={{ position: 'absolute', zIndex: 9999, width: '100%' }}>
      <Button
        onClick={handleOpen}
        style={{
          backgroundColor: '#E65022',
          color: 'black',
          border: '9px',
          fontFamily: 'Albra',
        }}
      >
        Revisar
      </Button>
      <Badge
        sx={{ cursor: 'default' }}
        badgeContent={amountOfInfluencersToReviewOffer}
        color="warning"
      >
        <NotificationsIcon color="action" />
      </Badge>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 700,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              zIndex: 9999,
              backgroundColor: 'white',
              flexShrink: '0',
              borderRadius: 2,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '37.8px',
                  letterSpacing: '0.005em',
                  textAlign: 'left',
                  padding: '20px',
                }}
              >
                Nuevas Solicitudes
              </Typography>
            </Grid>
            <br />
            <div style={{ height: 400, width: '100%', zIndex: 99999999 }}>
              <DataGrid rows={acceptedOffers} columns={columns} />
            </div>
            <br />
          </Box>
        </div>
      </Modal>
    </div>
  );
};
