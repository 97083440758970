import Box from '@mui/material/Box';
import '../../dashboard.css';
import { Copyright } from './Copyrigth';
import {List} from './components';


export const Home = () => {
  return (
    <>
      <Box component="main">
        <List />
        <Box sx={{ paddingTop: 40 }}>
          <Copyright />
        </Box>
      </Box>
    </>
  );
};
