import { Stack, Typography, IconButton, Grid } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import {
  getAllBusinessToApprove
} from "../../../services/api/business";
import { Business } from "../../../interfaces";
import { useTable } from "../../../hooks";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface Props {
  newRequests?: number;
  business?: Business;
}

const ArrowIconButton = ({ href }: { href: string }) => {
  return (
    <Link to={href}>
      <IconButton sx={{ color: "black" }}>
        <ArrowCircleRightIcon />
      </IconButton>
    </Link>
  );
};

export const Statistics = ({ newRequests }: Props) => {
  const tableProps = useTable({
    queryKey: "busines",
    queryFn: getAllBusinessToApprove,
  });


  const unverifiedBusiness = (
    (tableProps.data?.business as Business[]) || []
  ).filter((busines) => busines.verified === null);

  return (

    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      pt={4}
      className="text-center"
      width={"80%"}
      sx={{ padding: "8px" }}
    >
      <StatisticCard
        title={"NUEVAS SOLICITUDES"}
        subtitle={unverifiedBusiness.length}
        buttonHref={"registrations"} />
    </Stack>
  );
};

const StatisticCard = ({ title, subtitle, buttonHref = undefined }: any) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Stack spacing={2} direction="row">
          <Card
            sx={{ minWidth: 49 + "%", height: 150, boxCardStyle }}
          >
            <CardContent>
              <Typography sx={footerHeaderCardStyle}>{title}</Typography>
              <Typography sx={footerValueCardStyle}>
                {subtitle}
                {buttonHref && <ArrowIconButton href={buttonHref} />}
              </Typography>
              <Typography sx={footerDescriptionCardStyle}>en el ultimo mes</Typography>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

const boxCardStyle = {
  minWidth: "12rem",
  height: "110.377px",
  borderRadius: "9px",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px #A7A7A7",
  display: "flex",
  flexGrow: 1,
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "space-evenly",
};

const footerHeaderCardStyle = {
  color: "#000",
  textAlign: " center",
  fontFamily: " Albra",
  fontSize: " 16px",
  fontStyle: " normal",
  fontWeight: 500,
  lineHeight: " 8px",
  textTransform: " uppercase",
  marginTop: "25px",
  marginBottom: "25px",
};

const footerValueCardStyle = {
  color: "rgba(255, 90, 38, 0.50)",
  fontFamily: " Poppins",
  fontSize: " 20px",
  fontStyle: " normal",
  fontWeight: 700,
  lineHeight: " normal",
};

const footerDescriptionCardStyle = {
  color: "#000",
  fontFamily: "Brandon Grotesque",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 390,
  lineHeight: "normal",
};
