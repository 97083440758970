import React, { useState } from 'react';
import { AdminForm } from '../Admins/views/AdminForm';
import { AdminsTable } from '../Admins/views/AdminsTable';
import { useCRUDModal } from '../../hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Button, Modal } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from 'react-redux';

export const Admins = () => {
  const [admins, setAdmins] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: 'admin',
    // updateFn: updateInfluencer,
  });
  const [isEdited, setIsEdited] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCellClick = (event: any) => {
    const { row, field } = event;

    setAdmins(row);

    switch (field) {
      case 'approve':
        updateMutation.mutate({ ...row, verified: true });
        break;
      case 'reject':
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };

  const handleEditButtonClick = () => {
    setIsEdited(true);
  };

  const handleExitEditMode = () => {
    setIsEdited(false);
  };

  const { role } = useSelector((state: any) => state.auth);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={newAdminStyle}>
            {!isEdited && <AdminForm closeEvent={handleClose} />}
          </Box>
        </Modal>
      </div>
      {role === 'superAdmin' && (
        <Button
          aria-label="add"
          sx={iconButtonNewStyle}
          variant="contained"
          endIcon={<AddCircleIcon />}
          onClick={handleOpen}
        >
          Nuevo
        </Button>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <AdminsTable
              admins={admins}
              setAdmins={setAdmins}
              handleCellClick={handleCellClick}
              isEdited={isEdited}
              onEditButtonClick={handleEditButtonClick}
              onExitEditMode={handleExitEditMode}
            />
          }
        />
        <Route path="/*" element={<Navigate to={'/admins'} />} />
      </Routes>
    </>
  );
};

const iconButtonNewStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  color: 'black',
  width: '100px',
  height: '36px',
  flexShrink: 0,
  marginRight: 5,
  marginTop: 1,
  backgroundColor: '#E65022',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  '&:focus': {
    backgroundColor: '#E65022',
  },
  fontWeight: 'bold',
  position: 'fixed',
  bottom: 16,
  right: 16,
};

const newAdminStyle = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 540,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
