import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  Container,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Business, ExtendedBusinesss } from '../../../interfaces';
import { updateBusiness } from '../../../services/api/business';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormLabel from '@mui/material/FormLabel';
interface BusinessEditModalProps {
  businesId: string;
  businesEdit: ExtendedBusinesss;
  closeEvent: () => void;
}

const MySwal = withReactContent(Swal);
export const BusinesEditModal: React.FC<BusinessEditModalProps> = ({
  businesId,
  businesEdit,
  closeEvent,
}) => {
  const [editedBusiness, setEditedBusiness] =
    useState<ExtendedBusinesss>(businesEdit);

  const handleEditClick = (business: Business) => {
    setEditedBusiness(business);
    setEditShowErrors({
      name: false,
      email: false,
      managerFullName: false,
      city: false,
      category: false,
      membershipType: false,
      phoneNumber: false,
      country: false,
      creditCost: false,
      instagram: false,
      tiktok: false,
      website: false,
      description: false,
      amountOfOffers: false,
      amountOfPublicationsPerOffer: false,
      amountOfInfluencersPerOffer: false,
    });
  };
  const [editShowErrors, setEditShowErrors] = useState({
    name: false,
    email: false,
    managerFullName: false,
    city: false,
    category: false,
    membershipType: false,
    phoneNumber: false,
    country: false,
    creditCost: false,
    instagram: false,
    tiktok: false,
    website: false,
    description: false,
    amountOfOffers: false,
    amountOfPublicationsPerOffer: false,
    amountOfInfluencersPerOffer: false,
  });

  const handleSaveEdit = async () => {
    // Validación específica para el campo "Full Name"
    if (!editedBusiness?.name?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }

    // Validación específica para el campo "Email"
    if (!editedBusiness?.email) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: false,
      }));
    }

    // Validación específica para el campo " managerFullName"
    if (!editedBusiness?.managerFullName) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        managerFullName: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        managerFullName: false,
      }));
    }

    // Validación específica para el campo "City"
    if (!editedBusiness?.city) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        city: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        city: false,
      }));
    }
    // Validación específica para el campo "Numero de Telefono"
    if (!editedBusiness?.phoneNumber) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: false,
      }));
    }
    // Validación específica para el campo "Country"
    if (!editedBusiness?.country) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        country: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        country: false,
      }));
    }

    // Validación específica para el campo "creditCost"
    if (!editedBusiness?.creditCost) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        creditCost: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        creditCost: false,
      }));
    }
    // Validación específica para el campo "instagram"
    if (!editedBusiness?.instagram) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagram: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagram: false,
      }));
    }
    // Validación específica para el campo "tiktok"
    if (!editedBusiness?.tiktok) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktok: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktok: false,
      }));
    }
    // Validación específica para el campo "website"
    if (!editedBusiness?.website) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        website: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        website: false,
      }));
    }

    // Validación específica para el campo "description"
    if (!editedBusiness?.description) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        description: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        description: false,
      }));
    }

    // Validación específica para el campo "amountOfOffers"
    if (!editedBusiness?.amountOfOffers) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffers: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffers: false,
      }));
    }
    // Validación específica para el campo "amountOfPublicationsPerOffer"
    if (!editedBusiness?.amountOfPublicationsPerOffer) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: false,
      }));
    }
    // Validación específica para el campo "amountOfInfluencersPerOffer"
    if (!editedBusiness?.amountOfInfluencersPerOffer) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: false,
      }));
    }

    // Validación general de todos los campos
    if (
      !editedBusiness?.name ||
      !editedBusiness?.email ||
      !editedBusiness?.managerFullName ||
      !editedBusiness?.city ||
      !editedBusiness?.category ||
      !editedBusiness?.membershipType ||
      !editedBusiness?.phoneNumber ||
      !editedBusiness?.country ||
      !editedBusiness?.creditCost ||
      !editedBusiness?.instagram ||
      !editedBusiness?.tiktok ||
      !editedBusiness?.website ||
      !editedBusiness?.description ||
      !editedBusiness?.amountOfOffers ||
      !editedBusiness?.amountOfPublicationsPerOffer ||
      !editedBusiness?.amountOfInfluencersPerOffer
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title:
          'Se ha editado correctamente la información de ' +
          (editedBusiness?.name || ''),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
          confirmButton: 'swal2-custom-button',
        },
      });

      const updatedConfig = await updateBusiness(editedBusiness);
      //setIsEditing();
      setEditedBusiness(updatedConfig);
      window.location.reload();
      console.log(
        'Cambios guardados exitosamente. Datos actualizados:',
        updatedConfig
      );
    } catch (error) {
      console.error('Error al guardar la edición de la membresía:', error);
    }
  };
  const handleCancelEdit = () => {
    closeEvent();
  };

  const membershipTypes = ['bronze', 'silver', 'gold'];
  const category = ['food', 'beauty', 'nightlife', 'fitness'];
  const renderEditForm = () => (
    <form style={formStyle}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            {/* Columna Izquierda */}
            <Grid item>
              {/* name */}
              <FormLabel
                htmlFor="name"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Nombre del Comercio
              </FormLabel>
              <TextField
                type="text"
                name="name"
                required
                value={editedBusiness?.name || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    name: e.target.value,
                  })
                }
                error={editShowErrors.name}
                helperText={editShowErrors.name ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* email */}
              <FormLabel
                htmlFor="email"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Email
              </FormLabel>
              <TextField
                type="string"
                name="email"
                required
                value={editedBusiness?.email?.toString() || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    email: e.target.value,
                  })
                }
                error={editShowErrors.email}
                helperText={editShowErrors.email ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/*  managerFullName */}
              <FormLabel
                htmlFor="managerFullName"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Nombre del Encargado
              </FormLabel>
              <TextField
                type="string"
                name="managerFullName"
                required
                value={editedBusiness?.managerFullName?.toString() || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    managerFullName: e.target.value,
                  })
                }
                error={editShowErrors.managerFullName}
                helperText={
                  editShowErrors.managerFullName ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* city */}
              <FormLabel
                htmlFor="city"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Ciudad
              </FormLabel>
              <TextField
                type="string"
                name="city"
                required
                value={editedBusiness?.city?.toString() || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    city: e.target.value,
                  })
                }
                error={editShowErrors.city}
                helperText={editShowErrors.city ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* Categoria */}
              <FormLabel
                htmlFor="category"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Categorías
              </FormLabel>
              <FormControl fullWidth sx={textFieldStyle}>
                <Select
                  id="category"
                  value={editedBusiness?.category || ''}
                  onChange={(e) =>
                    setEditedBusiness({
                      ...editedBusiness!,
                      category: e.target.value as string,
                    })
                  }
                  inputProps={{
                    name: 'category',
                    id: 'category',
                  }}
                >
                  {category.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              {/* Tipo de Membresia */}
              <FormLabel
                htmlFor="membershipType"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Tipo de Mmembresia
              </FormLabel>
              <FormControl fullWidth sx={textFieldStyle}>
                <Select
                  id="membershipType"
                  value={editedBusiness?.membershipType || ''}
                  onChange={(e) =>
                    setEditedBusiness({
                      ...editedBusiness!,
                      membershipType: e.target.value as string,
                    })
                  }
                  inputProps={{
                    name: 'membershipType',
                    id: 'membership-type',
                  }}
                >
                  {membershipTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              {/* phoneNumber */}
              <FormLabel
                htmlFor="phoneNumber"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Número de Teléfono
              </FormLabel>
              <TextField
                type="tel"
                name="phoneNumber"
                value={editedBusiness?.phoneNumber || ''}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const formattedValue = inputValue
                    .replace(/\D/g, '')
                    .slice(0, 12);
                  setEditedBusiness({
                    ...editedBusiness!,
                    phoneNumber: formattedValue,
                  });
                }}
                error={editShowErrors.phoneNumber}
                helperText={editShowErrors.phoneNumber ? 'Campo requerido' : ''}
                inputProps={{
                  pattern: '[0-9]*',
                  maxLength: 12,
                }}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* amountOfInfluencersPerOffer */}
              <FormLabel
                htmlFor="amountOfInfluencersPerOffer"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Cantidad de Influencers por Oferta
              </FormLabel>
              <TextField
                name="amountOfInfluencersPerOffer"
                required
                value={
                  editedBusiness?.amountOfInfluencersPerOffer?.toString() || ''
                }
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedBusiness({
                    ...editedBusiness!,
                    amountOfInfluencersPerOffer:
                      inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.amountOfInfluencersPerOffer}
                helperText={
                  editShowErrors.amountOfInfluencersPerOffer
                    ? 'Campo requerido'
                    : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            {/* Columna Derecha */}
            <Grid item>
              {/* creditCost */}
              <FormLabel
                htmlFor="creditCost"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Costo de Créditos
              </FormLabel>
              <TextField
                name="creditCost"
                required
                value={editedBusiness?.creditCost?.toString() || ''}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedBusiness({
                    ...editedBusiness!,
                    creditCost: inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.creditCost}
                helperText={editShowErrors.creditCost ? 'Campo requerido' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* instagram */}
              <FormLabel
                htmlFor="instagram"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Instagram
              </FormLabel>
              <TextField
                type="text"
                name="instagram"
                value={editedBusiness?.instagram || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    instagram: e.target.value,
                  })
                }
                error={editShowErrors.instagram}
                helperText={editShowErrors.instagram ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* tiktok */}
              <FormLabel
                htmlFor="tiktok"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Tik Tok
              </FormLabel>
              <TextField
                type="text"
                name="tiktok"
                value={editedBusiness?.tiktok || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    tiktok: e.target.value,
                  })
                }
                error={editShowErrors.tiktok}
                helperText={editShowErrors.tiktok ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* website */}
              <FormLabel
                htmlFor="website"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Sitio Web
              </FormLabel>
              <TextField
                type="text"
                name="website"
                value={editedBusiness?.website || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    website: e.target.value,
                  })
                }
                error={editShowErrors.website}
                helperText={editShowErrors.website ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* description */}
              <FormLabel
                htmlFor="description"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Descripción
              </FormLabel>
              <TextField
                type="text"
                name="description"
                value={editedBusiness?.description || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    description: e.target.value,
                  })
                }
                error={editShowErrors.description}
                helperText={editShowErrors.description ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* amountOfOffers */}
              <FormLabel
                htmlFor="amountOfOffers"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Cantidad de Ofertas
              </FormLabel>
              <TextField
                name="amountOfOffers"
                required
                value={editedBusiness?.amountOfOffers?.toString() || ''}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedBusiness({
                    ...editedBusiness!,
                    amountOfOffers: inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.amountOfOffers}
                helperText={
                  editShowErrors.amountOfOffers ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* country */}
              <FormLabel
                htmlFor="country"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                País
              </FormLabel>
              <TextField
                type="text"
                name="country"
                value={editedBusiness?.country || ''}
                onChange={(e) =>
                  setEditedBusiness({
                    ...editedBusiness!,
                    country: e.target.value,
                  })
                }
                error={editShowErrors.country}
                helperText={editShowErrors.country ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* amountOfPublicationsPerOffer */}
              <FormLabel
                htmlFor="amountOfPublicationsPerOffer"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Cantidad de Publicaciones por Oferta
              </FormLabel>
              <TextField
                name="amountOfPublicationsPerOffer"
                required
                value={
                  editedBusiness?.amountOfPublicationsPerOffer?.toString() || ''
                }
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedBusiness({
                    ...editedBusiness!,
                    amountOfPublicationsPerOffer:
                      inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.amountOfPublicationsPerOffer}
                helperText={
                  editShowErrors.amountOfPublicationsPerOffer
                    ? 'Campo requerido'
                    : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              sx={buttonGuardarStyle}
              title="Guardar"
              onClick={handleSaveEdit}
            >
              <Typography sx={buttonTypographyStyle}>{'GUARDAR'}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button sx={buttonCancelarStyle} onClick={closeEvent}>
              <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );

  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Comercio: {editedBusiness?.name}
          </Typography>
        </Box>
      </div>
      <Box sx={{ m: 2 }} />
      <IconButton sx={closIconStyle} title="Cancelar" onClick={closeEvent}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#dddddd4a',
          width: '550px',
          height: '700px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          {renderEditForm()}
        </Container>
      </div>
      <Box sx={{ m: 2 }} />
    </>
  );
};
const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
  marginbottom: '10px',
};

const formStyle = {
  width: '100%',
  display: 'flex',
};
const textFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    width: 200,
    height: 40,
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '0px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
