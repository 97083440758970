import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { getAllAdmins, deleteAdmins } from '../../../services/api/admin';
import { Admins, ExtendedAdmin } from '../../../interfaces';
import { useTable } from '../../../hooks/useTable';
import { CustomTable } from '../../../common/components/CustomTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import { AdminsDetails } from './AdminsDetails';
import { AdminEditForm } from './AdminEditForm';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useSelector } from 'react-redux';

interface Props {
  admins?: Admins[];
  setAdmins: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
  onEditButtonClick: () => void;
  onExitEditMode: () => void;
  isEdited: boolean;
  searchResults?: any;
}

const roleIcons = {
  trainer: <PersonIcon style={{ color: 'blue' }} />,
  admin: <SupervisorAccountIcon style={{ color: 'red' }} />,
  accountant: <AccountBalanceIcon style={{ color: 'green' }} />,
  superAdmin: <VerifiedUserIcon style={{ color: 'purple' }} />,
};

const renderRole = ({ value }: GridRenderCellParams) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {roleIcons[value as keyof typeof roleIcons]}
      <Typography variant="body2" sx={{ marginLeft: 1 }}>
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </Typography>
    </Box>
  );
};

const MySwal = withReactContent(Swal);

export const AdminsTable = ({
  handleCellClick,
  onExitEditMode,
  searchResults,
}: Props) => {
  const tableProps = useTable({
    queryKey: 'admin',
    queryFn: getAllAdmins,
  });

  const { role } = useSelector((state: any) => state.auth);

  // Modal de Edición
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  // Modal de detalles
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [isDetailsVisible] = useState(false);
  const [selectedAdmins] = useState<Admins | null>(null);

  // admin
  const [isEditing, setIsEditing] = useState(false);
  const [editedAdmins, setEditedAdmins] = useState<ExtendedAdmin | null>(null);

  const handleEditClick = (admin: Admins) => {
    setIsEditing(true);
    setEditedAdmins({
      id: admin.id,
      email: admin.email,
      role: admin.role,
      fullName: admin.fullName,
      password: admin.password,
    });
    openEditModal();
  };

  const handleDeleteClick = async (admin: Admins) => {
    try {
      await deleteAdmins(admin.id);
      tableProps.refetch();
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: `El Administrador ${
          admin.fullName || ''
        } fue eliminado correctamente`,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
          confirmButton: 'swal2-custom-button',
        },
      });
    } catch (error) {
      console.error('Error al eliminar el administrador', error);
    }
  };

  const renderEditButtons = (row: Admins) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {(role === 'superAdmin' ||
        (role === 'admin' && row.role !== 'superAdmin')) && (
        <>
          <IconButton
            sx={iconButtonStyle}
            title="Editar"
            onClick={() => handleEditClick(row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            sx={iconButtonStyle}
            title="Eliminar"
            onClick={() => handleDeleteClick(row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </div>
  );

  
  const columns: GridColDef[] = [
    { flex: 1, field: 'email', headerName: 'Email', minWidth: 200 },
    {
      flex: 1,
      field: 'name',
      headerName: 'Nombre',
      minWidth: 200,
      renderCell: ({ row }: GridRenderCellParams) => row.fullName,
    },
    {
      flex: 1,
      field: 'role',
      headerName: 'Role',
      headerAlign: 'center',
      align: 'center',
      renderCell: renderRole,
      minWidth: 150,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 200,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams) => renderEditButtons(row),
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            {isDetailsVisible && selectedAdmins && (
              <AdminsDetails
                adminsDetails={selectedAdmins}
                closeEvent={handleClose}
              />
            )}
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={editModalOpen}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalEditStyles}>
            {isEditing && editedAdmins && editedAdmins.id && (
              <AdminEditForm
                adminId={editedAdmins.id}
                adminEdit={editedAdmins}
                closeEvent={closeEditModal}
              />
            )}
          </Box>
        </Modal>
      </div>
      <CustomTable
        title="Administradores"
        columns={columns}
        rows={tableProps.data}
        setActive={handleCellClick}
        {...tableProps}
      >
       {/*<Stack
          spacing={2}
          width={'100%'}
          direction={'row'}
          justifyContent={'right'}
          marginBottom={2}
        >
          <form>
            <TextField
              type="text"
              placeholder="Buscar"
              variant="outlined"
              size="small"
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                sx: { borderRadius: '200px' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>*/}
      </CustomTable>
    </>
  );
};

const ModalStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 540,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  flexShrink: '0',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};

const ModalEditStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 540,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};

const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};
