import { useMemo, useState, useEffect, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';

const baseURL = process.env.REACT_APP_SOCKET_URL;

interface SocketState {
  socket: Socket;
  isOnline: boolean;
}

const dynamicId = '83aa0bef-1987-4d66-8ed0-50dc7d483cf3';
const dynamicRole = 'superAdmin';

localStorage.setItem('dynamicId', dynamicId);
localStorage.setItem('dynamicRole', dynamicRole);

const storedId = localStorage.getItem('dynamicId');
const storedRole = localStorage.getItem('dynamicRole');

const useSocket = (serverPath: string) => {
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const socket = useMemo(() => {
    const socketInstance = io(
      'https://monkfish-app-hn3j8.ondigitalocean.app/chat',
      {
        auth: {
          id: storedId,
          role: storedRole,
        },
      }
    );

    socketInstance.on('connect', () => {
      console.log('connected');
      setIsOnline(true);
    });

    socketInstance.on('disconnect', () => {
      console.log('disconnected');
      setIsOnline(false);
    });

    return socketInstance;
  }, []);

  const connectSocket = useCallback(() => {
    socket.connect();
  }, [socket]);

  const disconnectSocket = useCallback(() => {
    socket.disconnect();
  }, [socket]);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket]);

  return {
    socket,
    isOnline,
    connectSocket,
    disconnectSocket,
    storedId,
  };
};

export default useSocket;
