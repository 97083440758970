import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCBRucrBoLt-525i8CgVRRb4oWYmaOiUPM",
  authDomain: "lit-app-fa2b2.firebaseapp.com",
  projectId: "lit-app-fa2b2",
  storageBucket: "lit-app-fa2b2.appspot.com",
  messagingSenderId: "711836618336",
  appId: "1:711836618336:web:d8642212db234da305bd05"
};

const FirebaseApp = initializeApp(firebaseConfig);
const FirebaseAuth = getAuth(FirebaseApp)

export {
  FirebaseApp,
  FirebaseAuth,
};
