import { configureStore } from '@reduxjs/toolkit'

import { authSlice } from './auth'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,

  },
  //TODO: Retirar cuando salga a prod, e inhabilitar las devtools tmb
  devTools: { trace: true }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch