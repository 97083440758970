import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  TextField,
  Button,
  Container,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ConfigLit, ExtendedConfigLit } from '../../../interfaces';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { updateConfig } from '../../../services/api/config';
import FormLabel from '@mui/material/FormLabel';

const MySwal = withReactContent(Swal);
interface ConfigEditModalProps {
  configId: string;
  configEdit: ExtendedConfigLit;
  closeEvent: () => void;
}
export const ConfigEditModal: React.FC<ConfigEditModalProps> = ({
  configId,
  configEdit,
  closeEvent,
}) => {
  const [editedConfig, setEditedConfig] =
    useState<ExtendedConfigLit>(configEdit);
  const handleEditClick = (config: ConfigLit) => {
    setEditedConfig(config);
    setEditShowErrors({
      '0-30k': false,
      '30-70k': false,
      '70-150k': false,
      '150-500k': false,
      '500k-1M': false,
      '1M+': false,
    });
  };
  const [editShowErrors, setEditShowErrors] = useState({
    '0-30k': false,
    '30-70k': false,
    '70-150k': false,
    '150-500k': false,
    '500k-1M': false,
    '1M+': false,
  });

  const handleSaveEdit = async () => {
    // Validación específica para el campo "30-70k"
    if (isNaN(Number(editedConfig?.['30-70k']))) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '30-70k': true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '30-70k': false,
      }));
    }

    // Validación específica para el campo "70-150k"
    if (isNaN(Number(editedConfig?.['70-150k']))) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '70-150k': true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '70-150k': false,
      }));
    }

    // Validación específica para el campo "150-500k"
    if (isNaN(Number(editedConfig?.['150-500k']))) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '150-500k': true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '150-500k': false,
      }));
    }

    // Validación específica para el campo "500k-1M"
    if (isNaN(Number(editedConfig?.['500k-1M']))) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '500k-1M': true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '500k-1M': false,
      }));
    }

    // Validación específica para el campo "1M+"
    if (isNaN(Number(editedConfig?.['1M+']))) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '1M+': true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        '1M+': false,
      }));
    }
    // Validación general de todos los campos
    if (
      !editedConfig?.['0-30k'] ||
      !editedConfig?.['30-70k'] ||
      !editedConfig?.['70-150k'] ||
      !editedConfig?.['150-500k'] ||
      !editedConfig?.['500k-1M'] ||
      !editedConfig?.['1M+']
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }
    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Editado Correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      const updatedConfig = await updateConfig({ ...editedConfig });
      // setIsEditing(false);
      setEditedConfig(updatedConfig);
      //tableProps.refetch();
      window.location.reload();
      console.log(
        'Cambios guardados exitosamente. Datos actualizados:',
        updatedConfig
      );
    } catch (error) {
      console.error('Error al guardar la edición de los creditos:', error);
    }
  };

  const renderEditForm = () => (
    <form style={formStyle}>
      {['0-30k', '30-70k', '70-150k', '150-500k', '500k-1M', '1M+'].map(
        (range) => (
          <>
            <FormLabel
              htmlFor={range}
              required
              sx={{
                fontSize: '0.8rem',
              }}
            >
              {range}
            </FormLabel>
            <TextField
              key={range}
              type="text"
              required
              value={
                editedConfig ? (editedConfig[range] as string)?.toString() : ''
              }
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '');
                setEditedConfig({
                  ...editedConfig!,
                  [range]: inputValue === '' ? 0 : Number(inputValue),
                });
              }}
              error={editShowErrors[range as keyof typeof editShowErrors]}
              helperText={
                editShowErrors[range as keyof typeof editShowErrors]
                  ? 'Campo requerido'
                  : ''
              }
              sx={textFieldStyle}
            />
          </>
        )
      )}
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              sx={buttonGuardarStyle}
              title="Guardar"
              onClick={handleSaveEdit}
            >
              <Typography sx={buttonTypographyStyle}>{'GUARDAR'}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button sx={buttonCancelarStyle} onClick={closeEvent}>
              <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );

  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Configuración de Creditos
          </Typography>
        </Box>
      </div>
      <Box sx={{ m: 2 }} />

      <IconButton sx={closIconStyle} onClick={closeEvent}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#F6F6F6',
          width: '470px',
          height: '500px',
          marginTop: '20px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          {renderEditForm()}
        </Container>
      </div>
    </>
  );
};
const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const formStyle = {
  width: '100%',
};
const textFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
