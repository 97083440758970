import React, { useState } from 'react';

import {
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  Container,
} from '@mui/material';
import { updateInfluencer } from '../../../services';

import { Influencer, ExtendedInfluencer } from '../../../interfaces';
import { Statistics } from '../components';
import '../../../index.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';

interface InfluencersEditModalProps {
  influencerId: string;
  influencerEdit: ExtendedInfluencer;
  closeEvent: () => void;
}
const MySwal = withReactContent(Swal);
export const InfluencersEditModal: React.FC<InfluencersEditModalProps> = ({
  influencerId,
  influencerEdit,
  closeEvent,
}) => {
  const [editedInfluencers, setEditedInfluencers] =
    useState<ExtendedInfluencer>(influencerEdit);
  const handleEditClick = (influencers: Influencer) => {
    setEditedInfluencers(influencers);
    setEditShowErrors({
      fullName: false,
      email: false,
      credits: false,
      instagram: false,
      tiktok: false,
      city: false,
      tiktokFollowers: false,
      instagramFollowers: false,
      defaultCredits: false,
      cancellations: false,
    });
  };

  const [editShowErrors, setEditShowErrors] = useState({
    fullName: false,
    email: false,
    credits: false,
    instagram: false,
    tiktok: false,
    city: false,
    tiktokFollowers: false,
    instagramFollowers: false,
    defaultCredits: false,
    cancellations: false,
  });
  const handleSaveEdit = async () => {
    // Validación específica para el campo "Full Name"
    if (!editedInfluencers?.fullName?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        fullName: false,
      }));
    }

    // Validación específica para el campo "Email"
    if (!editedInfluencers?.email) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        email: false,
      }));
    }

    // Validación específica para el campo "Creditos"
    if (!editedInfluencers?.credits) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        credits: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        credits: false,
      }));
    }

    // Validación específica para el campo "Instagram"
    if (!editedInfluencers?.instagram) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagram: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagram: false,
      }));
    }

    // Validación específica para el campo "Tiktok"
    if (!editedInfluencers?.tiktok?.trim()) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktok: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktok: false,
      }));
    }

    // Validación específica para el campo "City"
    if (!editedInfluencers?.city) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        city: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        city: false,
      }));
    }
    // Validación específica para el campo "tiktokFollowers"
    if (!editedInfluencers?.tiktokFollowers) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktokFollowers: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        tiktokFollowers: false,
      }));
    }
    // Validación específica para el campo "instagramFollowers"
    if (!editedInfluencers?.instagramFollowers) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagramFollowers: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        instagramFollowers: false,
      }));
    }
    // Validación específica para el campo "defaultCredits"
    if (!editedInfluencers?.defaultCredits) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        defaultCredits: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        defaultCredits: false,
      }));
    }
    // Validación específica para el campo "cancelations"
    if (!editedInfluencers?.cancellations) {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        cancellations: true,
      }));
      return;
    } else {
      setEditShowErrors((prevErrors) => ({
        ...prevErrors,
        cancellations: false,
      }));
    }

    // Validación general de todos los campos
    if (
      !editedInfluencers?.fullName ||
      !editedInfluencers?.email ||
      !editedInfluencers?.credits ||
      !editedInfluencers?.instagram ||
      !editedInfluencers?.tiktok ||
      !editedInfluencers?.city ||
      !editedInfluencers?.tiktokFollowers ||
      !editedInfluencers?.instagramFollowers ||
      !editedInfluencers?.defaultCredits ||
      !editedInfluencers?.cancellations
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'center',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title:
          'Se ha editado correctamente la información de ' +
          (editedInfluencers?.fullName || ''),
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
          confirmButton: 'swal2-custom-button',
        },
      });

      const updatedConfig = await updateInfluencer(editedInfluencers);
      //setIsEditing(false);
      setEditedInfluencers(updatedConfig);
      //tableProps.refetch();
      window.location.reload();
      console.log(
        'Cambios guardados exitosamente. Datos actualizados:',
        updatedConfig
      );
    } catch (error) {
      console.error('Error al guardar la edición de la membresía:', error);
    }
  };
  
  const renderEditForm = () => (
    <form style={formStyle}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            {/* Columna Izquierda */}
            <Grid item>
              {/* fullName */}
              <FormLabel
                htmlFor="fullName"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Nombre
              </FormLabel>
              <TextField
                type="text"
                name="fullName"
                required
                value={editedInfluencers?.fullName || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    fullName: e.target.value,
                  })
                }
                error={editShowErrors.fullName}
                helperText={editShowErrors.fullName ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* email */}
              <FormLabel
                htmlFor="email"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Email
              </FormLabel>
              <TextField
                type="string"
                name="email"
                required
                value={editedInfluencers?.email?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    email: e.target.value,
                  })
                }
                error={editShowErrors.email}
                helperText={editShowErrors.email ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* credits */}
              <FormLabel
                htmlFor="credits"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Créditos
              </FormLabel>
              <TextField
                name="credits"
                required
                value={
                  editedInfluencers ? editedInfluencers.credits?.toString() : ''
                }
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    credits: inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.credits}
                helperText={editShowErrors.credits ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* instagram */}
              <FormLabel
                htmlFor="instagram"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Instagram
              </FormLabel>
              <TextField
                type="string"
                name="instagram"
                required
                value={editedInfluencers?.instagram?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    instagram: e.target.value,
                  })
                }
                error={editShowErrors.instagram}
                helperText={editShowErrors.instagram ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* tiktok */}
              <FormLabel
                htmlFor="tiktok"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Tik Tok
              </FormLabel>
              <TextField
                type="string"
                name="tiktok"
                required
                value={editedInfluencers?.tiktok?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    tiktok: e.target.value,
                  })
                }
                error={editShowErrors.tiktok}
                helperText={editShowErrors.tiktok ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} direction="column">
            {/* Columna Derecha */}
            <Grid item>
              {/* city */}
              <FormLabel
                htmlFor="city"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Ciudad
              </FormLabel>
              <TextField
                type="string"
                name="city"
                required
                value={editedInfluencers?.city?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    city: e.target.value,
                  })
                }
                error={editShowErrors.city}
                helperText={editShowErrors.city ? 'Campo requerido' : ''}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* tiktokFollowers*/}
              <FormLabel
                htmlFor="tiktokFollowers"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Tik Tok Seguidores
              </FormLabel>
              <TextField
                type="string"
                name="tiktokFollowers"
                required
                value={editedInfluencers?.tiktokFollowers?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    tiktokFollowers: e.target.value,
                  })
                }
                error={editShowErrors.tiktokFollowers}
                helperText={
                  editShowErrors.tiktokFollowers ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* instagramFollowers*/}
              <FormLabel
                htmlFor="instagramFollowers"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Instagram Seguidores
              </FormLabel>
              <TextField
                type="string"
                name="instagramFollowers"
                required
                value={editedInfluencers?.instagramFollowers?.toString() || ''}
                onChange={(e) =>
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    instagramFollowers: e.target.value,
                  })
                }
                error={editShowErrors.instagramFollowers}
                helperText={
                  editShowErrors.instagramFollowers ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* defaultCredits */}
              <FormLabel
                htmlFor="defaultCredits"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Créditos por defecto
              </FormLabel>
              <TextField
                name="defaultCredits"
                required
                value={
                  editedInfluencers
                    ? editedInfluencers.defaultCredits?.toString()
                    : ''
                }
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    defaultCredits: inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.defaultCredits}
                helperText={
                  editShowErrors.defaultCredits ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item>
              {/* cancellations */}
              <FormLabel
                htmlFor="cancellations"
                required
                sx={{
                  fontSize: '0.8rem',
                }}
              >
                Cancelaciones
              </FormLabel>
              <TextField
                name="cancellations"
                required
                value={
                  editedInfluencers
                    ? editedInfluencers.cancellations?.toString()
                    : ''
                }
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, '');
                  setEditedInfluencers({
                    ...editedInfluencers!,
                    cancellations: inputValue === '' ? 0 : Number(inputValue),
                  });
                }}
                error={editShowErrors.cancellations}
                helperText={
                  editShowErrors.cancellations ? 'Campo requerido' : ''
                }
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              sx={buttonGuardarStyle}
              title="Guardar"
              onClick={handleSaveEdit}
            >
              <Typography sx={buttonTypographyStyle}>{'GUARDAR'}</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button sx={buttonCancelarStyle} onClick={closeEvent}>
              <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );

  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar Influencer: {editedInfluencers?.fullName}
          </Typography>
        </Box>
      </div>
      <Box sx={{ m: 2 }} />
      <IconButton sx={closIconStyle} title="Cancelar" onClick={closeEvent}>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#F6F6F6',
          width: '500px',
          height: '470px',
          marginTop: '20px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          {renderEditForm()}
        </Container>
      </div>
    </>
  );
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const formStyle = {
  width: '100%',
  marginTop: '20px',
  display: 'flex',
};
const textFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    width: 200,
    height: 40,
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '2px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
