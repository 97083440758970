import { Grid, Typography } from "@mui/material";
import pallette from "../../theme/pallete";

interface Props {
  children: JSX.Element | JSX.Element[];
  title?: string;
  color?: boolean;
  sx?: any;
}

export const AuthLayout = ({
  children,
  title = "",
  color = true,
  sx,
}: Props) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 4, backgroundColor: "#FFFFFF" }}
    >
      {children}
    </Grid>
  );
};
