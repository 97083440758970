import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
export const Copyright = () => {
  return (
    <footer>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        <Link
          component={RouterLink}
          color="inherit"
          to="/terms"
          target="_blank"
        >
          Términos & Condiciones
        </Link>
      </Typography>
    </footer>
  );
};
