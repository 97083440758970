import { privateAxiosInstance } from "../axiosConfig";

import { Admins, CreateAdmin, ExtendedAdmin } from "../../interfaces";

export const getAllAdmins = async (): Promise<{ admins: Admins[] }> => {
  const response = await privateAxiosInstance.get(`/admin`);
  return response.data;
};


export const updateAdmin = async (data: ExtendedAdmin) => {
  const { id, ...newValues } = data;
  const response = await privateAxiosInstance.patch<any>(
    `/admin/${id}`,
    newValues
  );
  return response.data;
};


export const createAdmins = async (datos: CreateAdmin) => {
  const adminisnuevos = {
    email: datos.email,
    role: datos.role,
    fullName: datos.fullName,
    password: datos.password,
  };
  const response = await privateAxiosInstance.post<any>("/admin", {
    ...adminisnuevos,
  });
  return response.data;
};

export const deleteAdmins = async (id: string): Promise<any> => {
  const response = await privateAxiosInstance.delete<any>(`/admin/${id}`);
  return response.data;
};
