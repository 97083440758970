import { useState } from "react";
import {
  Stack,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { ReturnButton } from "../components/ReturnButton";
import { CustomTable } from "../../../common/components";
import { InfluencerDetails } from "./InfluencerDetails";
import {
  getAllInfluencersToApprove,
  acceptOrRejectInfluencer,
} from "../../../services";
import { useTable } from "../../../hooks";
import { Influencer } from "../../../interfaces";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
//icons
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


interface Props {
  influencer?: Influencer;
  setInfluencer: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
}
const MySwal = withReactContent(Swal);
export const InfluencerRegistrations = ({
  setInfluencer,
  handleCellClick,
}: Props) => {
  const tableProps = useTable({
    queryKey: "influencer",
    queryFn: getAllInfluencersToApprove,
  });
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [selectedInfluencers, setSelectedInfluencers] =
    useState<Influencer | null>(null);

  //Modal de detalles
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInfluencersDetailsClick = (influencers: Influencer) => {
    setSelectedInfluencers(influencers);
    setIsDetailsVisible(true);
    handleOpen();
  };


  const unverifiedInfluencers = (
    (tableProps.data?.influencers as Influencer[]) || []
  ).filter((influencer) => influencer.verified === null);

  const handleAccept = async (influencerId: string) => {
    try {
      MySwal.fire({
        position: "center",
        icon: "success",
        title: "Aceptado Correctamente",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: "small-swal-popup",
          container: "my-swal",
        },
      });
      await acceptOrRejectInfluencer(influencerId, { approved: true });
      tableProps.refetch();
    } catch (error) {
      console.error("Error al aceptar al influencer:", error);
    }
  };
  const handleReject = async (influencerId: string) => {
    try {
      MySwal.fire({
        position: "center",
        icon: "success",
        title: "Influencer Rechazado.",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: "small-swal-popup",
          container: "my-swal",
        },
      });
      await acceptOrRejectInfluencer(influencerId, { approved: false });
      tableProps.refetch();
    } catch (error) {
      console.error("Error rejecting influencer:", error);
    }
  };

  const renderActionsCell = ({ row }: GridRenderCellParams) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        sx={iconButtonStyle}
        title="Aceptar"
        onClick={() => handleAccept(row.id)}
      >
        <DoneOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Rechazar"
        onClick={() => handleReject(row.id)}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Ver más Información"
        onClick={() => handleInfluencersDetailsClick(row)}
      >
        <InfoOutlinedIcon />
      </IconButton>
    </div>
  );

  const renderCell =
    (property: keyof Influencer) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <>
            {property === "fullName" && row.photoUrl && (
              <>
                <Avatar
                  src={row.photoUrl}
                  alt={row.fullName}
                  sx={{ marginRight: "8px", borderRadius: "50%" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "0.875rem" }}>
                    {row[property]}
                  </Typography>
                  <Typography variant="caption">{row.email}</Typography>
                </Box>
              </>
            )}

            {property === "fullName" && !row.photoUrl && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.875rem" }}>
                  {row[property]}
                </Typography>
                <Typography variant="caption">{row.email}</Typography>
              </Box>
            )}

            {property !== "fullName" && (
              <Typography>{row[property]} </Typography>
            )}
          </>
        </Box>
      );

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Nombre",
      flex: 1,
      width: 125,
      headerClassName: "table-header",
      renderCell: renderCell("fullName"),
    },
    {
      field: "instagram",
      headerName: "Instagram",
      flex: 1,
      minWidth: 125,
      align: "center",
      headerClassName: "table-header",
      headerAlign: "center",
      renderCell: renderCell("instagram"),
    },
    {
      field: "province",
      headerName: "Provincia",
      flex: 1,
      minWidth: 125,
      align: "center",
      headerClassName: "table-header",
      headerAlign: "center",
      renderCell: renderCell("province"),
    },
    {
      field: "city",
      headerName: "Ciudad",
      flex: 1,
      minWidth: 125,
      align: "center",
      headerClassName: "table-header",
      headerAlign: "center",
      renderCell: renderCell("city"),
    },
    {
      field: "verified",
      headerName: "Acciones",
      flex: 1,
      minWidth: 200,
      headerClassName: "table-header",
      renderCell: renderActionsCell,
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            {isDetailsVisible && selectedInfluencers && (
              <InfluencerDetails
                influencerDetails={selectedInfluencers}
                closeEvent={handleClose}
              />
            )}
          </Box>
        </Modal>
      </div>
      <CustomTable
        title="Nuevas Solicitudes de Influencers"
        columns={columns}
        rows={unverifiedInfluencers}
        setActive={handleCellClick}
        {...tableProps}
      >
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              ...iconBackButtonStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReturnButton />
          </Box>
        </Stack>
      </CustomTable>
    </>
  );
};
const iconBackButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
};

const iconButtonStyle = {
  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  borderRadius: "9px",
  background: "#FFF",
  width: "35px",
  height: "36px",
  flexShrink: 0,
  marginRight: 1,
};

const ModalStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 650,
  height: 630,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
