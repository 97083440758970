import {
  Stack,
  Typography,
  IconButton,
  Chip,
  Box,
} from '@mui/material';
import { ReturnButton } from '../components/ReturnButton';
import { CustomTable } from '../../../common/components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  acceptOrRejectBusiness,
  getAllBusinessToApprove,
} from '../../../services/api/business';
import { useTable } from '../../../hooks';
import { Business } from '../../../interfaces';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { BusinessDetails } from './BusinessDetails';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';


interface Props {
  business?: Business;
  setBusiness: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
}
const MySwal = withReactContent(Swal);
export const BusinessRegistrations = ({
  setBusiness,
  handleCellClick,
}: Props) => {
  const tableProps = useTable({
    queryKey: 'busines',
    queryFn: getAllBusinessToApprove,
  });
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );
  //Modal de detalles
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleBusinessDetailsClick = (business: Business) => {
    setSelectedBusiness(business);
    setIsDetailsVisible(true);
    handleOpen();
  };

  const unverifiedBusiness = (
    (tableProps.data?.business as Business[]) || []
  ).filter((busines) => busines.verified === null);

  const handleAccept = async (businessId: string) => {
    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Aceptado Correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      await acceptOrRejectBusiness(businessId, { approved: true });
      tableProps.refetch();
    } catch (error) {
      console.error('Error al aceptar al comercio:', error);
    }
  };
  const handleReject = async (businessId: string) => {
    try {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Influencer Rechazado.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      await acceptOrRejectBusiness(businessId, { approved: false });
      tableProps.refetch();
    } catch (error) {
      console.error('Error rejecting influencer:', error);
    }
  };

  const renderActionsCell = ({ row }: GridRenderCellParams) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <IconButton
        sx={iconButtonStyle}
        title="Aceptar"
        onClick={() => handleAccept(row.id)}
      >
        <DoneOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Rechazar"
        onClick={() => handleReject(row.id)}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Ver más Información"
        onClick={() => handleBusinessDetailsClick(row)}
      >
        <InfoOutlinedIcon />
      </IconButton>
    </div>
  );
  const renderCell =
    (property: keyof Business) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <>
            {property === 'name' && row.photoUrl && (
              <>
                <Avatar
                  src={row.photoUrl}
                  alt={row.fullName}
                  sx={{ marginRight: '8px', borderRadius: '50%' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                    {row[property]}
                  </Typography>
                  <Typography variant="caption">{row.email}</Typography>
                </Box>
              </>
            )}

            {property === 'name' && !row.photoUrl && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                  {row[property]}
                </Typography>
                <Typography variant="caption">{row.email}</Typography>
              </Box>
            )}

            {property === 'instagram' ? (
              <a
                href={`https://instagram.com/${row[property]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{row[property]}</Typography>
              </a>
            ) : (
              <Typography>{row[property]}</Typography>
            )}
          </>
        </Box>
      );

  const MembershipBadge = ({ membershipType }: { membershipType: string }) => {
    if (!membershipType) {
      return null;
    }

    let color: string;
    switch (membershipType) {
      case 'bronze':
        color = '#7289da';
        break;
      case 'gold':
        color = '#f9a825';
        break;
      case 'silver':
        color = '#9e9e9e';
        break;
      default:
        color = '#ffa500';
    }

    const chipWidth = membershipType.length * 10 + 20;

    return (
      <Chip
        label={membershipType}
        style={{
          backgroundColor: color,
          color: 'white',
          borderRadius: '10px',
          width: chipWidth,
          height: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 125,
      headerClassName: 'table-header',
      renderCell: renderCell('name'),
    },
    {
      field: 'instagram',
      headerName: 'Instagram',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('instagram'),
    },
    {
      field: 'city',
      headerName: 'Ciudad',
      flex: 1,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('city'),
    },
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('category'),
    },
    {
      field: 'membershipType',
      headerName: 'Membresía',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams) => (
        <MembershipBadge membershipType={row.membershipType} />
      ),
    },
    {
      field: 'verified',
      headerName: 'Acciones',
      flex: 1,
      width: 200,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderActionsCell,
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
           {} {isDetailsVisible && selectedBusiness && (
              <BusinessDetails
                businessDetails={selectedBusiness}
                closeEvent={handleClose}
              />
            )}
          </Box>
        </Modal>
      </div>

      <CustomTable
        title="Nuevas Solicitudes"
        columns={columns}
        rows={unverifiedBusiness}
        setActive={handleCellClick}
        {...tableProps}
      >
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              ...iconBackButtonStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReturnButton />
          </Box>
        </Stack>
      </CustomTable>
    </>
  );
};

const iconBackButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
};

const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};

const ModalStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 490,
  height: 720,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
