import { useMemo, useState } from 'react';
import { startLoginWithEmailAndPassword } from '../../store/auth/thunks';
import { useAppDispatch, useAppSelector, useForm } from '../../hooks';
import {
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Alert,
  Grid,
} from '@mui/material';
import { AuthLayout } from '../../common/Layout/AuthLayout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import themeConfig from '../../config/themeConfig';
import { LitAdminLogo } from '../../assets/images/LitAdminLogo';
import { useNavigate } from 'react-router-dom';

const formData = {
  email: '',
  password: '',
};

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status, errorMessage } = useAppSelector((state) => state.auth);
  const { formState, onInputChange }: any = useForm(formData);
  const isAuthenticating = useMemo(() => status === 'checking', [status]);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(startLoginWithEmailAndPassword(formState)).then((result: any) => {
      if (result.success) {
        navigate('/home');
      }
    });
  };

  return (
    <AuthLayout>
      <Stack
        gap={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LitAdminLogo />
        </Box>

        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 1.5 }}>
            Bienvenido a {themeConfig.templateName}! 👋🏻
          </Typography>
        </Box>
        <Typography sx={loginTypography}>INICIAR SESIÓN</Typography>
        <TextField
          sx={{ ...inputStyle, width: '100%' }}
          name="email"
          value={formState.email}
          onChange={onInputChange}
          label="EMAIL"
          variant="standard"
          InputProps={{
            placeholder: 'email@example.com',
          }}
        />
        <TextField
          sx={{ ...inputStyle, width: '100%' }}
          name="password"
          value={formState.password}
          onChange={onInputChange}
          type={showPassword ? 'text' : 'password'}
          label="CONTRASEÑA"
          variant="standard"
          InputProps={{
            placeholder: 'Min. 8 caracteres',
            endAdornment: (
              <InputAdornment position="end">
                <span style={{ cursor: 'pointer' }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </span>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Grid item xs={12} display={!!errorMessage ? '' : 'none'}>
        <Alert severity="error">{errorMessage}</Alert>
      </Grid>
      <Button
        disabled={isAuthenticating}
        onClick={handleSubmit}
        sx={buttonStyle}
      >
        <Typography sx={buttonTypographyStyle}>
          {'INICIAR SESIÓN =>'}
        </Typography>
      </Button>
    </AuthLayout>
  );
};

const loginTypography = {
  color: '#000',
  fontFamily: 'Albra !important',
  fontSize: '64px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '157.5%',
  letterSpacing: '2.88px',
};

const inputStyle = {
  width: '100%',
  marginTop: '15px',
  marginBottom: '15px',
  '& .MuiFormLabel-root': {
    color: '#000',
    fontSize: '16px',
    fontFamily: 'Brandon Grotesque !important',
    fontStyle: 'normal',
    fontWeight: '390',
    lineHeight: '157.5%',
    letterSpacing: '0.72px',
  },
};

const buttonStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '104px',
  color: 'black',
  borderRadius: 0,
  padding: 0,
  backgroundColor: '#FF5A26',
  boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
};

const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '450',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};
