import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import {
  Avatar,
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  TextField,
  Box,
  Paper,
  IconButton,
} from '@mui/material';
import { ChatTitle } from './components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { styled } from '@mui/material/styles';
import useSocket from '../../hooks/useSocket';
import { set } from 'react-hook-form';
import { store } from '../../store/store';

interface ChatProps {}

interface Message {
  to: string;
  message: string;
  email: string;
  createdAt: string;
  from: string;
  [key: string]: any;
}

export const Chat: React.FC<ChatProps> = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [selectedChatName, setSelectedChatName] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [connectedChat, setConnectedChat] = useState<string | null>(null);
  const [isChatBoxVisible, setIsChatBoxVisible] = useState(false);
  const [adminName, setAdminName] = useState<string | null>(null);
  const [chatsMessages, setChatsMessages] = useState<Record<string, Message[]>>(
    {}
  );
  const [messageText, setMessageText] = useState<string>('');
  const [messagesChatSelected, setMessagesChatSelected] = useState<Message[]>(
    []
  );
  const [newMessagesCount, setNewMessagesCount] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    const storedMessages = localStorage.getItem('messages');
    if (storedMessages) {
      setChatsMessages(JSON.parse(storedMessages));
    }
  }, []);

  const storeMessagesComletely = (messages: Record<string, Message[]>) => {
    localStorage.setItem('messages', JSON.stringify(messages));
    setChatsMessages(messages);
  };

  const fetchListOfChats = () => {
    socket.emit('list-of-chats');
  };

  const resetNewMessagesCount = () => {
    if (selectedChatName && newMessagesCount[selectedChatName] > 0) {
      setNewMessagesCount((prevNewMessagesCount) => {
        const updatedNewMessagesCount = { ...prevNewMessagesCount };
        updatedNewMessagesCount[selectedChatName] = 0;
        return updatedNewMessagesCount;
      });
    }
  };

  const handleConnectToChat = (chatName: string) => {
    socket.emit('connect-to-chat', { email: chatName });
    setIsChatBoxVisible(true);
    setSelectedChat(chatName);
    setSelectedChatName(chatName);

    setNewMessagesCount((prevNewMessagesCount) => {
      const updatedNewMessagesCount = { ...prevNewMessagesCount };
      updatedNewMessagesCount[chatName] = 0;
      return updatedNewMessagesCount;
    });
  };
  const { socket, isOnline, connectSocket, disconnectSocket, storedId } =
    useSocket('/chat');

  useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    fetchListOfChats();

    const handleListOfChats = (receivedChats: any) => {
      setChats(receivedChats.chats);
    };

    const handleConnectedToChat = (chatInfo: any) => {
      setAdminName(chatInfo.msg);
    };

    const handleMessageFromServer = (message: any) => {
      console.log('Message from server: ', message);
      if (!message.to) {
        setChatsMessages((prevChatsMessages) => {
          const updatedChatsMessages = { ...prevChatsMessages };
          if (message.from in updatedChatsMessages) {
            updatedChatsMessages[message.from].push(message);
          } else {
            updatedChatsMessages[message.from] = [message];
          }
          console.log('updatedChatsMessages: ', updatedChatsMessages);
          storeMessagesComletely(updatedChatsMessages);

          setNewMessagesCount((prevNewMessagesCount) => {
            const updatedNewMessagesCount = { ...prevNewMessagesCount };
            if (message.from in updatedNewMessagesCount) {
              updatedNewMessagesCount[message.from] += 1;
            } else {
              updatedNewMessagesCount[message.from] = 1;
            }
            return updatedNewMessagesCount;
          });

          return updatedChatsMessages;
        });
      }
    };

    socket.on('list-of-chats', handleListOfChats);
    socket.on('connected-to-chat', handleConnectedToChat);
    socket.on('message-from-server', handleMessageFromServer);

    const cleanup = () => {
      socket.off('list-of-chats', handleListOfChats);
      socket.off('connected-to-chat', handleConnectedToChat);
      socket.off('message-from-server', handleMessageFromServer);
    };

    return cleanup;
  }, []);

  const handleSendMessage = (e: any) => {
    e.preventDefault();

    if (selectedChat && messageText.trim() !== '') {
      const messagePayload = {
        to: selectedChat,
        message: messageText,
        email: selectedChat,
        createdAt: new Date().toLocaleTimeString('es-AR', {
          hour: '2-digit',
          minute: '2-digit',
        }),
        from: '',
      };

      socket.emit('message-from-client', messagePayload);

      setChatsMessages((prevChatsMessages) => {
        const updatedChatsMessages = { ...prevChatsMessages };
        if (selectedChat in updatedChatsMessages) {
          updatedChatsMessages[selectedChat].push(messagePayload);
        } else {
          updatedChatsMessages[selectedChat] = [messagePayload];
        }

        storeMessagesComletely(updatedChatsMessages);
        return updatedChatsMessages;
      });

      setMessageText('');
    }
  };

  const cardContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cardContentElement = cardContentRef.current;

    const handleScroll = () => {
      resetNewMessagesCount();
    };

    if (cardContentElement) {
      cardContentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (cardContentElement) {
        cardContentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [resetNewMessagesCount]);

  const listStyle = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };

  const listItemStyle = {
    '&:hover': {
      backgroundColor: '#e0e0e0',
      cursor: 'pointer',
    },
  };
  const iconButtonStyle = {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    borderRadius: '9px',
    background: '#EA1E1E',
    color: '#FAFAFA',
    width: '35px',
    height: '36px',
    flexShrink: 0,
    marginRight: 1,
  };

  const onClose = () => {
    setIsChatBoxVisible(false);
    socket.emit('disconnect-customer-from-chat', { email: selectedChat });
    setSelectedChat(null);
    setSelectedChatName(null);
    localStorage.clear();
  };

  const BadgeContentSpan = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  }));
  const ChatTitle = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      width="54%"
      marginTop={5}
    >
      <Typography variant="h4">Chat</Typography>
    </Box>
  );

  const renderChatList = () => (
    <Card
      style={{
        padding: 3,
        border: '1px solid #f79845',
        borderRadius: '14px',
        width: 400,
      }}
    >
      <br />
      <Stack direction="row" spacing={2}>
        <Badge
          overlap="circular"
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Avatar
            alt="John Doe"
            src={process.env.PUBLIC_URL + '/images/avatars/ava6-bg.png'}
            sx={{ width: 65, height: 65 }}
          />
        </Badge>
      </Stack>
      <Typography variant="h6" textAlign="center" sx={{ marginBottom: 2 }}>
        Chats/Rooms
      </Typography>
      <List sx={listStyle}>
        {chats.map((chat: Record<string, number>, index: number) => (
          <React.Fragment key={index}>
            <ListItem
              alignItems="flex-start"
              sx={listItemStyle}
              onClick={() => {
                handleConnectToChat(Object.keys(chat)[0]);
                setSelectedChatName(Object.keys(chat)[0]);
              }}
              selected={selectedChat === Object.keys(chat)[0]}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Remy Sharp"
                  src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-3.webp"
                />
              </ListItemAvatar>
              <ListItemText primary={Object.keys(chat)[0]} />
              <Tooltip
                title="Personas en el Chat"
                placement="top"
                sx={{
                  position: 'absolute',
                  left: '53px',
                  top: '18px',
                }}
              >
                {String(Object.values(chat)[0]) === '2' ? (
                  <Badge
                    badgeContent={newMessagesCount[Object.keys(chat)[0]]}
                    color="primary"
                  ></Badge>
                ) : (
                  <Badge badgeContent={'!'} color="error"></Badge>
                )}
              </Tooltip>
              <Divider variant="inset" component="li" />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Card>
  );

  const renderChatBox = () => (
    <Card
      style={{
        padding: 3,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        minWidth: '100%',
      }}
    >
      <CardHeader
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Chat messages</Typography>
            <IconButton
              sx={iconButtonStyle}
              title="Cerrar"
              onClick={() => onClose()}
              style={{
                marginLeft: '30px',
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        }
        avatar={
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava5-bg.webp"
            alt="avatar"
            className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
        }
      ></CardHeader>
      <CardContent
        sx={{ overflowY: 'auto', maxHeight: '450px' }}
        id="messages-container"
        ref={cardContentRef}
      >
        {Object.entries(chatsMessages).map(([chatName, messages]) => {
          if (chatName === selectedChat) {
            return messages.map((message: Message, index: number) => {
              let messageContent = null;

              if (message.to === selectedChat) {
                messageContent = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      position: 'relative',
                      left: '0px',
                    }}
                  >
                    <Box style={{ marginRight: '16px' }}>
                      <Paper
                        className="p-3 ms-3"
                        style={{
                          borderRadius: '15px',
                          backgroundColor: '#CCCCCC',
                        }}
                      >
                        <Typography variant="body2" className="mb-0">
                          {message.message}
                        </Typography>
                        <div className="d-flex justify-content-between">
                          <p className="small mb-1 text-muted">
                            {message.createdAt}
                          </p>
                          {/* <p className="small mb-1">Juan Cruz</p> */}
                        </div>
                      </Paper>
                    </Box>
                    <div style={{ position: 'relative' }}>
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        alt="avatar"
                        className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                        width="60"
                      />
                    </div>
                  </div>
                );
              } else if (message.from === selectedChat) {
                messageContent = (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      right: '0px',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava5-bg.webp"
                        alt="avatar"
                        className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
                        width="60"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '16px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                        }}
                      >
                        <div style={{ marginLeft: '16px', flex: 1 }}>
                          <Paper
                            className="p-3 ms-3"
                            style={{
                              borderRadius: '15px',
                              backgroundColor: 'rgba(57, 192, 237, 0.2)',
                              position: 'relative',
                            }}
                          >
                            <Typography variant="body2" className="mb-2">
                              {message.message}
                            </Typography>
                            <div style={{ marginTop: '8px' }}>
                              <Typography
                                variant="body2"
                                className="small mb-1 text-muted"
                              >
                                {message.createdAt}
                              </Typography>
                            </div>
                          </Paper>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 0px 18px 0px',
                  }}
                >
                  {messageContent}
                </li>
              );
            });
          } else {
            return null;
          }
        })}
        <form
          style={{ width: '100%', display: '' }}
          onSubmit={handleSendMessage}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                id="input-element"
                fullWidth
                multiline
                label="Escribe Algo...."
                placeholder="Bio..."
                variant="outlined"
                value={messageText}
                onClick={() => {
                  resetNewMessagesCount();
                }}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );

  return (
    <Stack
      spacing={2}
      width={'100%'}
      direction={'row'}
      justifyContent={'space-between'}
      marginBottom={2}
    >
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          minHeight="100vh"
          paddingTop="20px"
        >
          <ChatTitle />
          <Grid
            container
            spacing={3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingLeft: '240px',
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={4}
              sx={{ minWidth: '300px', marginTop: '100px' }}
            >
              {renderChatList()}
            </Grid>
            <Grid item xs={10} sm={6} md={7} lg={6} sx={{ minWidth: '400px' }}>
              {isChatBoxVisible && renderChatBox()}
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};
