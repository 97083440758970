import React, { useState } from 'react';
import {
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  Tooltip,
  Container,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { InputAdornment } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Box from '@mui/material/Box';
import { createMembership } from '../../../services/api/memberships';
import { Memberships } from '../../../interfaces/index';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InfoIcon from '@mui/icons-material/Info';
interface MembershipFormProps {
  closeEvent: () => void;
}
export const MembershipModal: React.FC<MembershipFormProps> = ({
  closeEvent,
}) => {
  const MySwal = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [typeOfMembership, setTypeOfMembership] = useState('');
  const [membershipCost, setMembershipCost] = useState('');
  const [amountOfInfluencersPerOffer, setAmountOfInfluencersPerOffer] =
    useState('');
  const [amountOfOffersPerMonth, setAmountOfOffersPerMonth] = useState('');
  const [influencerFollowers, setInfluencerFollowers] = useState('');
  const [amountOfPublicationsPerOffer, setAmountOfPublicationsPerOffer] =
    useState('');

  const [showErrors, setShowErrors] = useState({
    typeOfMembership: false,
    membershipCost: false,
    amountOfInfluencersPerOffer: false,
    amountOfOffersPerMonth: false,
    influencerFollowers: false,
    amountOfPublicationsPerOffer: false,
  });

  const handleConfirm = async () => {
    if (!typeOfMembership.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        typeOfMembership: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        typeOfMembership: false,
      }));
    }

    // Validación específica para el campo "Costo"
    if (!membershipCost.trim()) {
      setShowErrors((prevErrors) => ({ ...prevErrors, membershipCost: true }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        membershipCost: false,
      }));
    }

    // Validación específica para el campo "Influencers por Oferta"
    if (!amountOfInfluencersPerOffer.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfInfluencersPerOffer: false,
      }));
    }

    // Validación específica para el campo "Ofertas por Mes"
    if (!amountOfOffersPerMonth.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffersPerMonth: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfOffersPerMonth: false,
      }));
    }

    // Validación específica para el campo "Seguidores del Influencer"
    if (!influencerFollowers.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        influencerFollowers: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        influencerFollowers: false,
      }));
    }

    // Validación específica para el campo "Publicaciones por Oferta"
    if (!amountOfPublicationsPerOffer.trim()) {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: true,
      }));
      return;
    } else {
      setShowErrors((prevErrors) => ({
        ...prevErrors,
        amountOfPublicationsPerOffer: false,
      }));
    }
    if (
      !typeOfMembership ||
      !membershipCost ||
      !amountOfInfluencersPerOffer ||
      !amountOfOffersPerMonth ||
      !influencerFollowers ||
      !amountOfPublicationsPerOffer
    ) {
      MySwal.fire({
        icon: 'error',
        position: 'bottom-end',
        title: 'Por favor, completa todos los campos.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      return;
    }

    const formData: Omit<Memberships, 'id'> = {
      typeOfMembership,
      membershipCost: parseFloat(membershipCost),
      amountOfInfluencersPerOffer: parseInt(amountOfInfluencersPerOffer),
      amountOfOffersPerMonth: parseInt(amountOfOffersPerMonth),
      influencerFollowers,
      amountOfPublicationsPerOffer: parseInt(amountOfPublicationsPerOffer),
      planId: null,
    };

    try {
      await createMembership(formData);
      MySwal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: 'Membresía creada correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error('Error al crear la membresía:', error);
    }
  };

  const followersHelperText =
    'Formato: 0-30k, 30-70k, 70-150k, 150-500k, 500k-1M, 1M+';
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <div>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Crear Membresia
          </Typography>
        </Box>
      </div>

      <Box sx={{ m: 2 }} />

      <IconButton sx={closIconStyle} title="Cancelar" onClick={closeEvent}>
        <CloseOutlinedIcon />
      </IconButton>
      <Grid container spacing={2} />
      <div
        style={{
          backgroundColor: '#F6F6F6',
          width: '500px',
          height: '450px',
          marginTop: '20px',
          borderRadius: 9,
        }}
      >
        <Container
          style={{ paddingTop: '2rem', height: '100%', maxWidth: '600px' }}
        >
          {/*Modal Form */}
          <form style={formStyle}>
            <TextField
              placeholder="Tipo de Membresia"
              type="text"
              name="typeOfMembership"
              sx={TextFieldStyle}
              value={typeOfMembership}
              onChange={(e) => setTypeOfMembership(e.target.value)}
              required
              error={showErrors.typeOfMembership}
              helperText={showErrors.typeOfMembership ? 'Campo requerido' : ''}
            />

            <TextField
              placeholder="Costo"
              name="membershipCost"
              sx={textFieldCostStyle}
              value={membershipCost}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '');
                setMembershipCost(inputValue);
              }}
              required
              error={showErrors.membershipCost}
              helperText={showErrors.membershipCost ? 'Campo requerido' : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              placeholder="Influencers por Oferta"
              type="text"
              name="amountOfInfluencersPerOffer"
              sx={TextFieldStyle}
              value={amountOfInfluencersPerOffer}
              onChange={(e) => setAmountOfInfluencersPerOffer(e.target.value)}
              required
              error={showErrors.amountOfInfluencersPerOffer}
              helperText={
                showErrors.amountOfInfluencersPerOffer ? 'Campo requerido' : ''
              }
            />

            <TextField
              placeholder="Ofertas por Mes"
              name="amountOfOffersPerMonth"
              sx={TextFieldStyle}
              value={amountOfOffersPerMonth}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '');
                setAmountOfOffersPerMonth(inputValue);
              }}
              required
              error={showErrors.amountOfOffersPerMonth}
              helperText={
                showErrors.amountOfOffersPerMonth ? 'Campo requerido' : ''
              }
            />

            <TextField
              placeholder="Seguidores del Influencer"
              type="text"
              name="influencerFollowers"
              sx={TextFieldStyle}
              value={influencerFollowers}
              onChange={(e) => setInfluencerFollowers(e.target.value)}
              required
              error={showErrors.influencerFollowers}
              helperText={
                <React.Fragment>
                  <span>
                    {showErrors.influencerFollowers ? 'Campo requerido. ' : ''}
                  </span>
                </React.Fragment>
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={followersHelperText} arrow>
                      <InfoIcon style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              placeholder="Publicaciones por Oferta"
              name="amountOfPublicationsPerOffer"
              sx={TextFieldStyle}
              value={amountOfPublicationsPerOffer}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '');
                setAmountOfPublicationsPerOffer(inputValue);
              }}
              required
              error={showErrors.amountOfPublicationsPerOffer}
              helperText={
                showErrors.amountOfPublicationsPerOffer ? 'Campo requerido' : ''
              }
            />

            <br />
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button sx={buttonGuardarStyle} onClick={handleConfirm}>
                    <Typography sx={buttonTypographyStyle}>Guardar</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button sx={buttonCancelarStyle} onClick={closeEvent}>
                    <Typography sx={buttonTypographyStyle}>Cancelar</Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};

const formStyle = {
  width: '100%',
  marginTop: '20px',
};
const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonGuardarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FF5A26',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};

const buttonCancelarStyle = {
  width: '130px',
  height: '40px',
  color: 'black',
  borderRadius: '20px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  flexShrink: 0,
  marginRight: 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
const buttonTypographyStyle = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '157.5%',
  letterSpacing: '0.1px',
};

const textFieldCostStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
const TextFieldStyle = {
  '& label .Mui-focused': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    minWidth: 280,
    minHeight: 40,
    height: '100%',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: '#E0E0E0',
    transition: 'border-color 120ms ease-in',
    marginBottom: '20px',
    '& fieldset': {
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused': {
      borderColor: '#FF9800',
      outline: '4px solid',
      outlineColor: '#FF5722',
    },
  },
};
