import React from 'react';
import { Avatar } from '@mui/material';

export const LitAdminLogo = () => {
  return (
    <Avatar
      alt="LitAdminLogo"
      src={process.env.PUBLIC_URL + '/litnuevologo.png'}
      sx={{ width: 60, height: 60 }}
    />
  );
};
