import React, { useState, useEffect } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Typography, IconButton, Box} from '@mui/material';

import {
  getAllMemberships,
  deleteMembership,
} from '../../../services/api/memberships';
import { Memberships, ExtendedMembership } from '../../../interfaces';
import { useTable } from '../../../hooks/useTable';
import { CustomTable } from '../../../common/components/CustomTable';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MembershipEditModal } from './MembershipEditModal';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface Props {
  memberships?: Memberships;
  setMemberships: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
  onEditButtonClick: () => void;
  onExitEditMode: () => void;
  isEdited: boolean;
}

const MySwal = withReactContent(Swal);
export const MembershipsTable = ({
  handleCellClick,
  onEditButtonClick,
  onExitEditMode,
}: Props) => {
  const tableProps = useTable({
    queryKey: '/memberships',
    queryFn: getAllMemberships,
  });
  //Orden de las membresias de menor a mayor costo
  const [sortedMemberships, setSortedMemberships] = useState<Memberships[]>([]);

  const handleSortMemberships = () => {
    if (tableProps.data) {
      const sortedData = [...tableProps.data].sort(
        (a, b) => a.membershipCost - b.membershipCost
      );
      setSortedMemberships(sortedData);
    }
  };

  useEffect(() => {
    handleSortMemberships();
  }, [tableProps.data]);
  //para los detalles de las membresias si se agregan
  const [setIsDetailsVisible] = useState(false);
  const [setSelectedMemberships] =
    useState<Memberships | null>(null);


  //Modal de Edición
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedMemberships, setEditedMemberships] =
    useState<ExtendedMembership | null>(null);

  const handleEditClick = (memberships: Memberships) => {
    setIsEditing(true);
    setEditedMemberships({
      id: memberships.id,
      typeOfMembership: memberships.typeOfMembership,
      membershipCost: memberships.membershipCost,
      amountOfInfluencersPerOffer: memberships.amountOfInfluencersPerOffer,
      amountOfOffersPerMonth: memberships.amountOfOffersPerMonth,
      influencerFollowers: memberships.influencerFollowers,
      amountOfPublicationsPerOffer: memberships.amountOfPublicationsPerOffer,
    });
    openEditModal();
  };

  const handleDeleteClick = async (memberships: Memberships) => {
    try {
      await deleteMembership(memberships.typeOfMembership);
      tableProps.refetch();
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'Membresía eliminada correctamente',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
    } catch (error) {
      console.error('Error al eliminar la membresía', error);
    }
  };

  const renderEditButtons = (row: Memberships) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        sx={iconButtonStyle}
        title="Editar"
        onClick={() => {
          onEditButtonClick();
          handleEditClick(row);
        }}
      >
        <Edit />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Eliminar"
        onClick={() => handleDeleteClick(row)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );

  const renderCell =
    (property: keyof Memberships) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Typography
          sx={{
            flex: 1,
            width: 125,
            align: 'center',
            p: 1,
          }}
        >
          {row[property]}
        </Typography>
      );

  const renderCostCell = ({ row }: GridRenderCellParams) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <AttachMoneyIcon style={{ marginRight: 4 }} />
      <Typography
        sx={{
          flex: 1,
          align: 'center',
        }}
      >
        {row.membershipCost}
      </Typography>
    </div>
  );

  type MembershipType = 'gold' | 'silver' | 'bronze' | 'platino';

  const MembershipTypeStyles: Record<
    MembershipType,
    { backgroundColor: string; color: string }
  > = {
    gold: { backgroundColor: 'gold', color: 'darkgoldenrod' },
    silver: { backgroundColor: 'silver', color: 'darkgray' },
    bronze: { backgroundColor: 'peru', color: 'saddlebrown' },
    platino: { backgroundColor: 'lightcoral', color: 'red' },
  };

  const membershipTypeMapping: Record<string, MembershipType> = {
    Oro: 'gold',
    Plata: 'silver',
    Platino: 'platino',
    Bronze: 'bronze',
  };

  const renderMembershipType = ({ value }: GridRenderCellParams) => {
    if (!value) {
      return null;
    }

    const membershipType = membershipTypeMapping[value] as MembershipType;
    const style = MembershipTypeStyles[membershipType] || {};
    return (
      <Box
        sx={{
          ...style,
          padding: '4px 8px',
          borderRadius: '8px',
          textAlign: 'center',
          width: 'fit-content',
          margin: 'auto',
        }}
      >
        <Typography variant="body2">{value}</Typography>
      </Box>
    );
  };
  const columns: GridColDef[] = [
    {
      field: 'typeOfMembership',
      headerName: 'Membresía',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderMembershipType,
    },
    {
      field: 'membershipCost',
      headerName: 'Costo',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCostCell,
    },
    {
      field: 'amountOfInfluencersPerOffer',
      headerName: 'Influencers',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('amountOfInfluencersPerOffer'),
    },
    {
      field: 'amountOfOffersPerMonth',
      headerName: 'Ofertas por mes',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('amountOfOffersPerMonth'),
    },
    {
      field: 'influencerFollowers',
      headerName: 'Seguidores',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('influencerFollowers'),
    },
    {
      field: 'amountOfPublicationsPerOffer',
      headerName: 'Publicacones',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('amountOfPublicationsPerOffer'),
    },
    {
      field: 'acctions',
      headerName: 'Acciones',
      flex: 1,
      minWidth: 200,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => renderEditButtons(row),
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={editModalOpen}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalEditStyles}>
            {isEditing && editedMemberships && editedMemberships.id && (
              <MembershipEditModal
                membershipsId={editedMemberships.id}
                membershipsEdit={editedMemberships}
                closeEvent={closeEditModal}
              />
            )}
          </Box>
        </Modal>
      </div>
      <CustomTable
        title="Membresias"
        columns={columns}
        rows={sortedMemberships}
        setActive={handleCellClick}
        {...tableProps}
      />
    </>
  );
};
const ModalEditStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  maxWidth: '90vw',
  maxHeight: '90vh',
  width: 520,
  height: 700,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};
