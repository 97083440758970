import { privateAxiosInstance } from "../axiosConfig";

import { Memberships } from "../../interfaces";

export const getAllMemberships = async (): Promise<{
  memberships: Memberships[];
}> => {
  const response = await privateAxiosInstance.get(`/memberships`);
  return response.data;
};

export const updateMemberships = async (datos: Partial<Memberships>) => {
  const { id, planId, ...newValues } = datos;
  const response = await privateAxiosInstance.patch<any>(
    `/memberships/${id}`,
    newValues
  );
  return response.data;
};

export const createMembership = async (
  datos: Omit<Memberships, "id" | "planId">
) => {
  const response = await privateAxiosInstance.post<any>("/memberships", datos);
  return response.data;
};

export const deleteMembership = async (
  typeOfMembership: string
): Promise<any> => {
  const response = await privateAxiosInstance.delete(
    `memberships/${typeOfMembership}`
  );
  return response.data;
};
