import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { useAppStore } from './appStore';
import { NavbarItem } from './NavbarItem';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { startLogOut } from '../../../store/auth/thunks';
import { NavLink } from 'react-router-dom';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
// icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Dashboard from '@mui/icons-material/Dashboard';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import './sidenav.css';
import { LitAdminLogo } from '../../../assets/images/LitAdminLogo';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const iconColorSx = { color: 'black' };

export default function Sidenav() {
  const [open, setOpen] = React.useState(true);
  const appStoreOpen = useAppStore((state) => state.dopen);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(startLogOut());
  };

  const theme = useTheme();
  return (
    <Drawer variant="permanent" open={appStoreOpen}>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(!open)}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <div id="logo" className="my-4 px-20">
        {/* Title 
        <LitAdminLogo />*/}
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <LitAdminLogo />
      </Box>
      <Divider />

      <Box sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <List
            className="nav-items"
            sx={{
              '& li:hover': {
                backgroundColor: '#FF5A26',
                borderTopRightRadius: '40px',
                borderBottomRightRadius: '40px',
              },
            }}
          >
            {items.map((item, index) => (
              <NavLink
                key={index}
                to={item.href}
                className="link"
                activeClassName="active"
              >
                <NavbarItem open={appStoreOpen} {...item} key={item.title} />
              </NavLink>
            ))}
            <Divider />
            <Tooltip
              title="Cerrar sesión"
              placement="right"
              arrow
              disableHoverListener={appStoreOpen}
            >
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={handleLogout}
                  sx={{
                    minHeight: 48,
                    justifyContent: appStoreOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: appStoreOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {<LogoutIcon sx={iconColorSx} />}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      fontFamily: 'Brandon Grotesque',
                      fontSize: '14px',
                      fontWeight: 420,
                      lineHeight: '21px',
                      letterSpacing: '0.10000000149011612px',
                      textAlign: 'left',
                      opacity: appStoreOpen ? 1 : 0,
                      ...iconColorSx,
                    }}
                  >
                    Cerrar sesión
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}

const items = [
  {
    icon: <Dashboard sx={iconColorSx} />,
    title: 'Dashboard',
    href: '/home',
  },
  {
    icon: <ShoppingBagOutlinedIcon sx={iconColorSx} />,
    title: 'Comercios',
    href: '/business',
  },
  {
    icon: <VerifiedOutlinedIcon sx={iconColorSx} />,
    title: 'Membresias',
    href: '/workers',
  },
  {
    icon: <ThumbUpAltOutlinedIcon sx={iconColorSx} />,
    title: 'Influencers',
    href: '/influencers',
  },

  {
    icon: <LibraryBooksOutlinedIcon sx={iconColorSx} />,
    title: 'Ofertas',
    href: '/offers',
  },
  {
    icon: <ForumIcon sx={iconColorSx} />,
    title: 'Chat',
    href: '/chat',
  },
  {
    icon: <ManageAccountsOutlinedIcon sx={iconColorSx} />,
    title: 'Admin General',
    href: '/admin',
  },
  {
    icon: <AdminPanelSettingsOutlinedIcon sx={iconColorSx} />,
    title: 'Admin',
    href: '/admins',
  },
];
