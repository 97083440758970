import React from 'react';
import {
  IconButton,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Stack,
  Tooltip,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CategoryIcon from '@mui/icons-material/Category';
import InfoIcon from '@mui/icons-material/Info';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

interface AdminsDetailsProps {
  id: string;
  createdAt: string;
  email: string;
  displayName: null;
  photoUrl: string | null;
  uid: string | null;
  role: string;
  fullName: string;
}

interface OnCloseFunction {
  closeEvent: () => void;
}

export const AdminsDetails: React.FC<{
  adminsDetails: AdminsDetailsProps;
  closeEvent: () => void;
}> = ({ adminsDetails, closeEvent }) => {
  const { createdAt, email, displayName, photoUrl, role, fullName } =
    adminsDetails;

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    const formattedDate: string = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  const cardContent1 = (
    <Grid item xs={12} xl={12}>
      <Grid
        container
        direction={{ xs: 'row' }}
        padding={2}
        sx={{
          backgroundColor: '#dddddd4a',
          borderRadius: '10px',
          width: '550px',
          height: '500px',
          alignItems: 'flex-start',
        }}
      >
        <Grid item xs={12} lg={8} sx={{ padding: 2 }}>
          <Stack
            direction={{ xs: 'column' }}
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              pb={2}
              sx={{ width: '100%' }}
            >
              <Stack direction="column" flexGrow={1}>
                <Typography variant="h6" fontWeight={'900'} fontSize={'16px'}>
                  Nombre : {fullName}
                </Typography>
                <Typography variant="h6" fontWeight={'700'} fontSize={'13px'}>
                  Nombre de Usuario: {displayName ? displayName : 'Sin info'}
                </Typography>
              </Stack>
              <Avatar
                variant="rounded"
                src={photoUrl ?? undefined}
                sx={{
                  width: '6rem',
                  height: '6rem',
                  marginLeft: 15,
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                }}
              />
            </Stack>
            <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
              <Typography variant="caption" style={{ marginLeft: '8px' }}>
                Rol: {role}
              </Typography>
              <Typography variant="caption" style={{ marginLeft: '8px' }}>
                Email: {email}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box sx={{ m: 2 }} />

      <IconButton
        sx={{ ...closIconStyle, marginBottom: '25px' }}
        title="Cancelar"
        onClick={closeEvent}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <Box sx={{ height: '20px' }} />
      <br />
      {cardContent1}
    </>
  );
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  marginBottom: 5,
};
