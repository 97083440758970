import axios, { InternalAxiosRequestConfig } from 'axios';
import { useAppDispatch } from '../hooks';
import { store } from '../store/store'
import { logOut } from '../store/auth';
import { startLogOut } from '../store/auth/thunks';
const baseURL = process.env.REACT_APP_API_URL;

const instancesData = {
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
};

export const privateAxiosInstance = axios.create(instancesData);
export const publicAxiosInstance = axios.create(instancesData);

privateAxiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	const token = localStorage.getItem('token')
	if (!token) store.dispatch(logOut({}))
	if (token && config.headers) {
		config.headers.Authorization = `${token}`;
	}

	return config;
});

privateAxiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 500) {
			const responseData = error.response.data;
			if (responseData && (responseData.code === 'auth/id-token-expired' || responseData.code === 'auth/argument-error')) {
				localStorage.removeItem('token')
				store.dispatch(startLogOut())
			}
		}
		return error
	}
);