import { useState } from 'react';
import {
  InfluencerCancellations,
  InfluencerRegistrations,
  InfluencersTable,
} from './views';
import { updateInfluencer } from '../../services';
import { useCRUDModal } from '../../hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Statistics } from '../Influencers/components/Statistics';

export const Influencers = () => {
  const [influencer, setInfluencer] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: 'influencer',
    updateFn: updateInfluencer,
  });
  const [isEdited, setIsEdited] = useState(false);

  const handleCellClick = (event: any) => {
    const { row, field } = event;

    setInfluencer(row);

    switch (field) {
      case 'approve':
        updateMutation.mutate({ ...row, verified: true });
        break;
      case 'reject':
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };
  const handleEditButtonClick = () => {
    setIsEdited(true);
  };

  const handleExitEditMode = () => {
    setIsEdited(false);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <InfluencersTable
                influencer={influencer}
                setInfluencer={setInfluencer}
                handleCellClick={handleCellClick}
                isEdited={isEdited}
                onEditButtonClick={handleEditButtonClick}
                onExitEditMode={handleExitEditMode}
              />
              <br />
              <Statistics />
            </>
          }
        />
        <Route
          path="/registrations"
          element={
            <InfluencerRegistrations
              setInfluencer={setInfluencer}
              handleCellClick={handleCellClick}
            />
          }
        />
        <Route path="/cancellations" element={<InfluencerCancellations />} />

        <Route path="/*" element={<Navigate to={'/influencers'} />} />
      </Routes>
    </>
  );
};
