import { useState } from 'react';
import { ConfigTable } from '../Admin/views/ConfigTable';
import { useCRUDModal } from '../../hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Typography, Grid, Card } from '@mui/material';
import Sidenav from '../../common/components/Navbar/Sidenav';
import Navbar from '../../common/components/Navbar/Navbar';

import CardHeader from '@mui/material/CardHeader';

export const Admin = () => {
  const [configlit, setConfig] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: 'config',
    // updateFn: updateInfluencer,
  });
  const handleCellClick = (event: any) => {
    const { row, field } = event;

    setConfig(row);

    switch (field) {
      case 'approve':
        updateMutation.mutate({ ...row, verified: true });
        break;
      case 'reject':
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };

  const [isEdited, setIsEdited] = useState(false);
  const handleEditButtonClick = () => {
    setIsEdited(true);
  };

  const handleExitEditMode = () => {
    setIsEdited(false);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ConfigTable
              configlit={configlit}
              setConfig={setConfig}
              handleCellClick={handleCellClick}
              isEdited={isEdited}
              onEditButtonClick={handleEditButtonClick}
              onExitEditMode={handleExitEditMode}
            />
          }
        />
        <Route path="/*" element={<Navigate to={'/admin'} />} />
      </Routes>
    </>
  );
};
