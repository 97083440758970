import { useMutation, useQueryClient } from '@tanstack/react-query'

interface Props<Entity, newEntity> {
    createFn?: (entity: newEntity) => Promise<Entity>,
    updateFn?: (entity: Partial<Entity>) => Promise<Entity>,
    deleteFn?: (entity: Entity) => Promise<Entity>,
    queryKey: string
}

export const useCRUDModal = <Entity, NewEntity>({ createFn, updateFn, deleteFn, queryKey }: Props<Entity, NewEntity>) => {
    const queryClient = useQueryClient()
    const invalidateQuery = () => queryClient.invalidateQueries([queryKey])

    const createMutation = useMutation({
        mutationFn:
            createFn,
        onSuccess:
            invalidateQuery
    })
    const updateMutation = useMutation({
        mutationFn:
            updateFn,
        onSuccess:
            invalidateQuery
    })
    const deleteMutation = useMutation({
        mutationFn:
            deleteFn,
        onSuccess:
            invalidateQuery
    })
    return {
        createMutation,
        updateMutation,
        deleteMutation
    }
}
