import { privateAxiosInstance } from "../axiosConfig";

import { Influencer } from "../../interfaces";

export const getAllInfluencers = async (
  page = 0,
  INFLUENCER_PER_PAGE = 0
): Promise<{ influencers: Influencer[]; maxInfluencers: number }> => {
  const offset: number = INFLUENCER_PER_PAGE * page!;
  const response = await privateAxiosInstance.get(
    `/influencers?limit=${INFLUENCER_PER_PAGE}&offset=${offset}`
  );
  return response.data;
};

export const updateInfluencer = async (datos: Partial<Influencer>) => {
  const {
    id,
    uid,
    displayName,
    photoUrl,
    blocked,
    bornDate,
    tiktok,
    verified,
    createdAt,
    ...newValues
  } = datos;
  const response = await privateAxiosInstance.patch<any>(
    `/influencers/${id}`,
    newValues
  );
  return response.data;
};

export const acceptOrRejectInfluencer = async (
  influencerId: string,
  options: { approved: boolean }
): Promise<any> => {
  const response = await privateAxiosInstance.get(
    `/influencers/approve-influencer/${influencerId}?approved=${options.approved}`
  );
  return response.data;
};

export const getAllInfluencersToApprove = async (
  page = 0,
  INFLUENCER_PER_PAGE = 0
): Promise<{ influencers: Influencer[]; maxInfluencers: number }> => {
  const offset: number = INFLUENCER_PER_PAGE * page!;
  const response = await privateAxiosInstance.get(
    `/influencers/find-all/influencers-to-approve?limit=${INFLUENCER_PER_PAGE}&offset=${offset}`
  );
  return response.data;
};

export const deleteInfluencer = async (id: string): Promise<any> => {
  const response = await privateAxiosInstance.delete(`/influencers/${id}`);
  return response.data;
};
