import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Navigation } from "./router/Navigation";

import ThemeProvider from "./theme";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Navigation />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
