import React, { useState, useEffect } from "react";
import { CustomTable } from "../../../common/components";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Typography, Stack, Box, Chip } from "@mui/material";
import Button from "@mui/material/Button";
import { getAllOffers } from "../../../services/api/offers";
import { useTable } from "../../../hooks";
import { Offers } from "../../../interfaces";
import { AcceptOffers } from "../components/AcceptOffers";

interface Props {
  offers?: Offers;
  setOffers: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
}

export const OffersTable = ({ setOffers, handleCellClick }: Props) => {
  const [selectedOfferId, setSelectedOfferId] = useState<string | null>(null);
  const tableProps = useTable({
    queryKey: "offers/find-all/web/",
    queryFn: getAllOffers,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (offerId: string) => {
    setSelectedOfferId(offerId);
    setIsModalOpen(true);
  };

  type Status = 'active' | 'pending';

  const statusStyles: Record<
    Status,
    { backgroundColor: string; color: string }
  > = {
    active: { backgroundColor: 'lightgreen', color: 'green' },
    //inactive: { backgroundColor: 'lightgray', color: 'gray' },
    pending: { backgroundColor: 'lightgray', color: 'gray' },
    //suspended: { backgroundColor: 'lightcoral', color: 'red' },
  };
  const renderStatus = ({ value }: GridRenderCellParams) => {
    const style = statusStyles[value as Status] || {};
    return (
      <Box
        sx={{
          ...style,
          padding: '4px 8px',
          borderRadius: '8px',
          textAlign: 'center',
          width: 'fit-content',
          margin: 'auto',
        }}
      >
        <Typography variant="body2">
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </Typography>
      </Box>
    );
  };
  const columns: GridColDef[] = [
    {
      field: 'campaignType',
      headerName: 'Tipo de Campaña',
      flex: 1,
      minWidth: 150,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          sx={{
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '390px',
            lineHeight: 'normal',
          }}
        >
          {row.campaignType}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Estado',
      headerAlign: 'center',
      flex: 1,
      minWidth: 150,
      headerClassName: 'table-header',
      renderCell: renderStatus,
    },

    {
      field: 'business',
      headerName: 'Comercio',
      flex: 1,
      minWidth: 150,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          sx={{
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '390px',
            lineHeight: 'normal',
          }}
        >
          {row.business ? row.business.name : 'N/A'}
        </Typography>
      ),
    },

    {
      field: 'publicationType',
      headerName: 'Tipo de Publicación',
      flex: 1,
      minWidth: 100,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          sx={{
            color: '#000',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '390px',
            lineHeight: 'normal',
          }}
        >
          {row.publicationType || 'N/A'}
        </Typography>
      ),
    } /*
    {
      field: "amountOfPublications",
      headerName: "Cantidad de Publicaciones",
      flex: 1,
      minWidth: 200,
      headerClassName: "table-header",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Typography
          sx={{
            color: "#000",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "390px",
            lineHeight: "normal",
          }}
        >
          {row.amountOfPublications}
        </Typography>
      ),
    },*/,
    {
      field: ' ',
      headerName: 'Acciones',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      minWidth: 200,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button onClick={() => handleOpenModal(row.id)}>
          <AcceptOffers
            offersId={row.id}
            amountOfInfluencersToReviewOffer={
              row.amountOfInfluencersToReviewOffer
            }
          />
        </Button>
      ),
    },
  ];
  return (
      <>
        <CustomTable
          title="Revisión de Ofertas"
          columns={columns}
          rows={tableProps.data?.offers}
          setActive={(event) => {
            handleCellClick(event);
            setSelectedOfferId(event.row.id || null);
          }}
          {...tableProps}
        />
      </>
  );
};
