import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseAuth } from '../firebase/config';
import { getUser } from '../services/api/auth';
import { login, logOut } from '../store/auth/authSlice';
import { useAppSelector } from './reduxHooks';

export const useCheckAuth = () => {
    const { status } = useAppSelector(state => state.auth);
    const dispatch = useDispatch();

    // const checkTokenResp = async () => {
    //     return await checkToken()
    // }
    useEffect(() => {
        onAuthStateChanged(FirebaseAuth, async (user) => {
            if (!user) return dispatch(logOut({}))
            const { uid, email, displayName, } = user
            const userInfo = await getUser(email!)
            dispatch(login({ uid, email, displayName, role: userInfo.role, fullName: userInfo.fullName }))
        })
    }, [dispatch])

    return {
        status
    }

}
