import { Stack, Box, Typography, IconButton } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import {
  getAllInfluencersToApprove,
} from "../../../services";
import { useTable } from "../../../hooks";
import { Influencer } from "../../../interfaces";
interface Props {
  newInfluencers?: number;
  cancelledInfluencers?: number;
  newRequests?: number;
  trendingCategory?: string;
  influencers?: Influencer;
}

const ArrowIconButton = ({ href }: { href: string }) => {
  return (
    <Link to={href}>
      <IconButton sx={{ color: "black" }}>
        <ArrowCircleRightIcon />
      </IconButton>
    </Link>
  );
};

export const Statistics = ({
  newInfluencers = 12,
  cancelledInfluencers = 3,
  newRequests,
  trendingCategory = "MODA",
}: Props) => {

  const tableProps = useTable({
    queryKey: "influencer",
    queryFn: getAllInfluencersToApprove,
  });

  const unverifiedInfluencers = (
    (tableProps.data?.influencers as Influencer[]) || []
  ).filter((influencer) => influencer.verified === null);
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      pt={4}
      className="text-center"
      width={"80%"}
      sx={{ padding: "8px" }}
    >
      <StatisticCard title={"NUEVOS INFLUENCERS"} subtitle={newInfluencers} />
      <StatisticCard
        title={"BAJAS"}
        subtitle={cancelledInfluencers}
        buttonHref={"cancellations"}
      />
      <StatisticCard
        title={"NUEVAS SOLICITUDES"}
        subtitle={unverifiedInfluencers.length}
        buttonHref={"registrations"}
      />
      <StatisticCard title={"RUBRO MAS ELEGIDO"} subtitle={trendingCategory} />
    </Stack>
  );
};

const StatisticCard = ({ title, subtitle, buttonHref = undefined }: any) => {
  return (

    <Box sx={boxCardStyle}>
      <Typography sx={footerHeaderCardStyle}>{title}</Typography>
      <Typography sx={footerValueCardStyle}>
        {subtitle}
        {buttonHref && <ArrowIconButton href={buttonHref} />}
      </Typography>
      <Typography sx={footerDescriptionCardStyle}>en el ultimo mes</Typography>
    </Box>
  );
};

const boxCardStyle = {
  minWidth: "12rem",
  height: "110.377px",
  borderRadius: "9px",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px #A7A7A7",
  display: "flex",
  flexGrow: 1,
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "space-evenly",
};

const footerHeaderCardStyle = {
  color: "#000",
  textAlign: " center",
  fontFamily: " Albra",
  fontSize: " 16px",
  fontStyle: " normal",
  fontWeight: 500,
  lineHeight: " 8px",
  textTransform: " uppercase",
};

const footerValueCardStyle = {
  color: "rgba(255, 90, 38, 0.50)",
  fontFamily: " Poppins",
  fontSize: " 20px",
  fontStyle: " normal",
  fontWeight: 700,
  lineHeight: " normal",
};

const footerDescriptionCardStyle = {
  color: "#000",
  fontFamily: "Brandon Grotesque",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 390,
  lineHeight: "normal",
};
