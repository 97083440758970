import React, { useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Typography, IconButton, Box } from '@mui/material';
import { getAllConfig } from '../../../services/api/config';
import { ConfigLit, ExtendedConfigLit } from '../../../interfaces';
import { useTable } from '../../../hooks/useTable';
import { CustomTable } from '../../../common/components/CustomTable';
import { Edit } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import { ConfigEditModal } from './ConfigEditModal';
import Modal from '@mui/material/Modal';

interface Props {
  configlit?: ConfigLit;
  setConfig: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
  onEditButtonClick: () => void;
  onExitEditMode: () => void;
  isEdited: boolean;
}

export const ConfigTable = ({
  handleCellClick,
  onEditButtonClick,
  onExitEditMode,
}: Props) => {
  const tableProps = useTable({
    queryKey: 'config',
    queryFn: getAllConfig,
  });

  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedConfig, setEditedConfig] = useState<ExtendedConfigLit | null>(
    null
  );

  const handleEditClick = (config: ConfigLit) => {
    setIsEditing(true);
    setEditedConfig({
      ...config,
    });

    openEditModal();
  };
  const renderEditButtons = (row: ConfigLit) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <>
        <IconButton
          sx={iconButtonStyle}
          title="Editar"
          onClick={() => {
            onEditButtonClick();
            handleEditClick(row);
          }}
        >
          <Edit />
        </IconButton>
      </>
    </div>
  );

  const renderCell =
    (property: keyof ConfigLit) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Typography
          sx={{
            flex: 1,
            width: 125,
            align: 'center',
            m: 0,
            p: 0,
          }}
        >
          {row[property]}
        </Typography>
      );

  const columns: GridColDef[] = [
    {
      field: '0-30k',
      headerName: '0-30k',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('0-30k'),
    },
    {
      field: '30-70k',
      headerName: '30-70k',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('30-70k'),
    },
    {
      field: '70-150k',
      headerName: '70-150k',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('70-150k'),
    },
    {
      field: '150-500k',
      headerName: '150-500k',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('150-500k'),
    },
    {
      field: '500k-1M',
      headerName: '500k-1M',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('500k-1M'),
    },
    {
      field: '1M+',
      headerName: '1M+',
      flex: 1,
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('1M+'),
    },
    /*{
      field: "creditsPerRecomendation",
      headerName: "Créditos por Recomendación",
      flex: 1,
      width: 200,
      align: "center",
      headerClassName: "table-header",
      renderCell: renderCell("creditsPerRecomendation"),
    },*/
    {
      field: 'acctions',
      headerName: 'Acciones',
      flex: 1,
      minWidth: 200,
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => renderEditButtons(row),
    },
  ];

  return (
    <>
      <div>
        <Modal
          open={editModalOpen}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalEditStyles}>
            {isEditing && editedConfig && editedConfig.id && (
              <ConfigEditModal
                configId={editedConfig.id}
                configEdit={editedConfig}
                closeEvent={closeEditModal}
              />
            )}
          </Box>
        </Modal>
      </div>
      <CustomTable
        title="Administración de Creditos"
        columns={columns}
        rows={tableProps.data}
        setActive={handleCellClick}
        {...tableProps}
      />
    </>
  );
};

const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};
const ModalEditStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 675,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
