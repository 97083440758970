import {
  Typography,
  Avatar,
  IconButton,
  Box,
  Grid,
  Tooltip,
  Stack,
  Link,
  Divider,
} from '@mui/material';
//icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Instagram} from '@mui/icons-material';
import { TiktokIcon } from '../components/TiktokIcon';

interface InfluencerDetailsProps {
  id: string;
  uid: string | null;
  fullName: string;
  email: string;
  displayName: string | null;
  photoUrl: string | null;
  blocked: boolean;
  bornDate: string;
  instagram: string | null;
  tiktok: string | null;
  verified: boolean;
  createdAt: string;
  city: string;
  province: string;
  tiktokFollowers: string;
  instagramFollowers: string;
  defaultCredits: number;
  credits: number;
  cancellations: number;
  amountOfReferedFriends: number;
}

interface OnCloseFunction {
  closeEvent: () => void;
}

export const InfluencerDetails: React.FC<{
  influencerDetails: InfluencerDetailsProps;
  closeEvent: () => void;
}> = ({ influencerDetails, closeEvent }) => {
  const {
    fullName,
    email,
    displayName,
    photoUrl,
    blocked,
    bornDate,
    instagram,
    tiktok,
    verified,
    createdAt,
    city,
    province,
    tiktokFollowers,
    instagramFollowers,
    defaultCredits,
    credits,
    cancellations,
    amountOfReferedFriends,
  } = influencerDetails;

  const socialLinks = [
    {
      name: 'Instagram',
      url: instagram,
      icon: <Instagram sx={{ color: '#E4405F' }} />,
      followers: instagramFollowers,
      title: 'Instagram',
    },
    {
      name: 'TikTok',
      url: tiktok,
      icon: <TiktokIcon />,
      followers: tiktokFollowers,
      title: 'TikTok',
    },
  ];

  const cardContent2 = (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Redes Sociales
      </Typography>
      {socialLinks.map((link) => (
        <Box key={link.name} display="flex" alignItems="center" mb={2}>
          <Box title={link.title} display="flex" alignItems="center">
            {link.icon}
          </Box>
          <Link
            href={link.url || '#'}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ml: 1, color: 'textSecondary' }}
          >
            {link.url ? link.url.split('www.')[1] : 'Sin info'}
          </Link>
          <Typography variant="body2" sx={{ ml: 2 }}>
            {link.followers ? link.followers : 'Sin info'}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <>
      <Box sx={{ m: 2 }} />

      <IconButton
        sx={{ ...closIconStyle, marginBottom: '25px' }}
        title="Cancelar"
        onClick={closeEvent}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <br />
      <Grid item xs={12} xl={12}>
        <Grid
          container
          direction={{ xs: 'row' }}
          padding={2}
          sx={{
            backgroundColor: '#dddddd4a',
            borderRadius: '10px',
            width: '600px',
            height: '520px',
            alignItems: 'flex-start',
          }}
        >
          <Grid item xs={12} lg={8} sx={{ padding: 2 }}>
            <Stack direction={{ xs: 'column' }} spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pb={2}
              >
                <Stack direction="column" flexGrow={1}>
                  <Typography variant="h6" fontWeight={900} fontSize="16px">
                    Influencer: {fullName}
                  </Typography>
                  <Typography variant="h6" fontWeight={700} fontSize="13px">
                    Usuario: {displayName ? displayName : 'Sin info'}
                  </Typography>
                </Stack>
                <Avatar
                  variant="rounded"
                  src={photoUrl ?? undefined}
                  sx={{
                    width: '6rem',
                    height: '6rem',
                    marginLeft: 40,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                  }}
                />
              </Stack>
              <Stack
                sx={{ '> span': { fontSize: '14px', lineHeight: '20px' } }}
              >
                <Typography variant="caption">
                  Fecha de nacimiento: {bornDate}
                </Typography>
                <Typography variant="caption">
                  Ubicación: {province} - {city}
                </Typography>
                <Typography variant="caption">Mail: {email}</Typography>
                <Typography variant="caption">
                  Creditos por Defecto: {defaultCredits}
                </Typography>
                <Typography variant="caption">Creditos: {credits}</Typography>
                <Typography variant="caption">
                  Cancelaciones: {cancellations}
                </Typography>
                <Typography variant="caption">
                  Cantidad de Amigos Referidos: {amountOfReferedFriends}
                </Typography>
                <Typography
                  variant="caption"
                  display="flex"
                  alignItems="center"
                >
                  Verificado:
                  <Tooltip
                    placement="left"
                    title={verified ? 'Verificado' : 'No Verificado'}
                  >
                    {verified ? (
                      <CheckCircleIcon color="success" sx={{ ml: 1 }} />
                    ) : (
                      <CloseIcon color="error" sx={{ ml: 1 }} />
                    )}
                  </Tooltip>
                </Typography>{' '}
              </Stack>
              <Divider variant="middle" sx={{ backgroundColor: 'black' }} />
              {cardContent2}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
