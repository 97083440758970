import {
  Navigate,
  Route,
  Routes,
  redirect,
  useNavigate,
} from 'react-router-dom';

import { AuthRoutes, DashboardRoutes } from '../routes/';

import { CheckingAuth } from '../common/components';

import { useCheckAuth } from '../hooks/useCheckAuth';

import { Terms } from '../pages';

export const Navigation = () => {
  const { status } = useCheckAuth();
  if (status === 'checking') {
    return <CheckingAuth />;
  }

  return (
    <Routes>
      {status === 'authenticated' ? (
        <Route path="/*" element={<DashboardRoutes />} />
      ) : (
        <Route path="/auth/*" element={<AuthRoutes />} />
      )}
      <Route path="terms" element={<Terms />} />
      <Route path="/*" element={<Navigate to={'/auth/login'} />} />
    </Routes>
  );
};
