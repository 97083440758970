import React  from 'react';
import {
  Typography,
  Avatar,
  IconButton,
  Box,
  Grid,
  Link,
  Stack,
  Divider,
  Tooltip,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { Instagram, Language } from '@mui/icons-material';
import { TiktokIcon } from '../../Influencers/components/TiktokIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

interface BusinessDetailsProps {
  id: string;
  email: string;
  verified: boolean;
  createdAt: string;
  displayName: string | null;
  photoUrl: string | null;
  uid: string | null;
  name: string;
  phoneNumber: string;
  country: string;
  creditCost: number;
  city: string;
  instagram: string;
  tiktok: string;
  website: string;
  managerRole: null;
  managerFullName: string;
  category: string;
  description: string;
  membershipActive: true;
  amountOfOffers: number;
  amountOfPublicationsPerOffer: number;
  amountOfInfluencersPerOffer: number;
  membershipType: string;
}

export const BusinessDetails: React.FC<{
  businessDetails: BusinessDetailsProps;
  closeEvent: () => void;
}> = ({ businessDetails, closeEvent }) => {
  const {
    email,
    verified,
    createdAt,
    photoUrl,
    name,
    phoneNumber,
    country,
    creditCost,
    city,
    instagram,
    tiktok,
    website,
    managerRole,
    managerFullName,
    category,
    description,
    membershipActive,
    amountOfOffers,
    amountOfPublicationsPerOffer,
    amountOfInfluencersPerOffer,
    membershipType,
  } = businessDetails;

  const socialLinks = [
    {
      name: 'Instagram',
      url: instagram,
      icon: <Instagram sx={{ color: '#E4405F' }} />,
      title: 'Instagram',
    },
    {
      name: 'TikTok',
      url: tiktok,
      icon: <TiktokIcon />,
      title: 'TikTok',
    },
    {
      name: 'Website',
      url: website,
      icon: <Language sx={{ color: '#000000' }} />,
      title: 'Sitio Web',
    },
  ];

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    const formattedDate: string = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  const cardContent1 = (
    <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Fecha de Creación: {formatDate(createdAt)}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Ubicación: {country}, {city}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Email: {email}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Categoría: {category}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Membresia: {membershipType}
      </Typography>
      <Typography
        variant="caption"
        display="flex"
        alignItems="center"
        style={{ marginLeft: '8px' }}
      >
        Verificado:
        <Tooltip
          placement="left"
          title={verified ? 'Verificado' : 'No Verificado'}
        >
          {verified ? (
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          ) : (
            <CloseIcon color="error" sx={{ ml: 1 }} />
          )}
        </Tooltip>
      </Typography>
      <Typography
        variant="caption"
        display="flex"
        alignItems="center"
        style={{ marginLeft: '8px' }}
      >
        Membresia:
        <Tooltip
          placement="left"
          title={membershipActive ? 'Activa' : 'Inactiva'}
        >
          {membershipActive ? (
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          ) : (
            <CloseIcon color="error" sx={{ ml: 1 }} />
          )}
        </Tooltip>
      </Typography>
    </Stack>
  );

  const cardContent2 = (
    <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
      <Typography variant="h6" gutterBottom>
        Contacto
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Teléfono: {phoneNumber}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Manager: {managerFullName}-({managerRole})
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Redes Sociales
      </Typography>
      {socialLinks.map((link) => (
        <Box key={link.name} display="flex" alignItems="center" mb={1}>
          <Box title={link.title} display="flex" alignItems="center">
            {link.icon}
          </Box>
          <Link
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ ml: 1, color: 'textSecondary' }}
          >
            {link.url || 'Sin info'}
          </Link>
        </Box>
      ))}
    </Stack>
  );



  return (
    <>
      <Box sx={{ m: 2 }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="left">
          <Typography variant="h6" fontWeight={'900'} fontSize={'25px'}>
            Información General
          </Typography>
        </Box>
        <IconButton
          sx={{ ...closIconStyle, marginBottom: '15px' }}
          title="Cancelar"
          onClick={closeEvent}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <br />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="column"
          padding={2}
          sx={{
            backgroundColor: '#dddddd4a',
            borderRadius: '10px',
            width: 400,
            height: 585,
            margin: 'auto',
            alignItems: 'flex-start',
          }}
        >
          <Grid item xs={12} lg={12} sx={{ padding: 2 }}>
            <Stack
              direction={{ xs: 'column' }}
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                pb={2}
                sx={{ width: '100%' }}
              >
                <Stack direction="column" flexGrow={1}>
                  <Typography variant="h6" fontWeight={900} fontSize="16px">
                    Influencer: {name}
                  </Typography>
                  <Typography variant="h6" fontWeight={700} fontSize="13px">
                    Usuario: {managerFullName ? managerFullName : 'Sin info'}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {description}
                  </Typography>
                </Stack>
                <Avatar
                  variant="rounded"
                  src={photoUrl ?? undefined}
                  sx={{
                    width: { xs: '4rem', sm: '6rem' },
                    height: { xs: '4rem', sm: '6rem' },
                    marginLeft: { xs: 0, sm: 15 },
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                  }}
                />
              </Stack>
              {cardContent1}
              <Divider
                variant="middle"
                sx={{ backgroundColor: 'black', width: '100%' }}
              />
              {cardContent2}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const closIconStyle = {
  position: 'absolute',
  right: '35px',
  top: '35px',
  borderRadius: '9px',
  background: '#FFF',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
};
