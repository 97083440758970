import { Route, Routes } from 'react-router-dom';

import { useAppDispatch } from '../hooks';
import {
  Business,
  Influencers,
  Memberships,
  Chat,
  Admin,
  Admins,
  Offers,
} from '../pages';
import { BusinessDetailsView } from '../pages/Business/views/BusinessDetailsView';
import { Home } from '../pages/Home/Home';
import Navbar from '../common/components/Navbar/Navbar';
import Sidenav from '../common/components/Navbar/Sidenav';

import { logOut } from '../store/auth';
import { DashboardLayout } from '../common/Layout';

export const DashboardRoutes = () => {
  const expireTimestampString = localStorage.getItem('expireTimestamp');
  const dispatch = useAppDispatch();

  if (expireTimestampString) {
    const parsedDate = new Date(parseInt(expireTimestampString!, 10));
    const currentDate = new Date();
    if (parsedDate < currentDate) {
      dispatch(logOut({}));
    }
  }

  return (
    <>
      <Navbar />
      <Sidenav />
      <DashboardLayout>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/workers/*" element={<Memberships />} />
          <Route path="/influencers/*" element={<Influencers />} />
          <Route path="/offers/*" element={<Offers />} />
          <Route path="/business/*" element={<Business />} />
          <Route
            path="business/details/:businessId"
            element={<BusinessDetailsView />}
          />
          <Route path="/admin" element={<Admin />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/admins" element={<Admins />} />
        </Routes>
      </DashboardLayout>
    </>
  );
};
