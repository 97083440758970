import React from 'react';
import { Box, IconButton, Toolbar, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';

import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import { useAppStore } from './appStore';
import { useSelector } from 'react-redux';

export const drawerWidth = 286;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  //border: '2px solid #ffcc80',
}));

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

export default function NavBar() {
  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);

  const { fullName } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.auth);

  const BadgeContentSpan = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: '#ffffff', color: '#2f2f2f' }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => updateOpen(!dopen)}
          >
            <MenuIcon />
          </IconButton>
          <StyledLink>
            <Stack direction="row" spacing={2}>
              <Badge
                overlap="circular"
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Avatar
                  alt="John Doe"
                  src={process.env.PUBLIC_URL + '/images/avatars/ava6-bg.png'}
                  sx={{ width: '2.5rem', height: '2.5rem' }}
                />
              </Badge>
              <Typography
                sx={{
                  marginLeft: '6px',
                  color: 'var(--black-high-emphasis, rgba(0, 0, 0, 0.87))',
                  fontFamily: 'Albra',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '30px',
                  letterSpacing: '0.15px',
                }}
              >
                {fullName}
              </Typography>
            </Stack>
          </StyledLink>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
