import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

interface UseTableProps {
  queryKey: string;
  queryFn: (actualPage: number, rowsPerPage: number) => Promise<any>;
}

export const useTable = ({ queryKey, queryFn }: UseTableProps) => {
  const [actualPage, setActualPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [maxRowsToShow, setMaxRowsToShow] = useState<number>(5);

  const {
    data,
    refetch,
    isLoading,
    // isError
  } = useQuery({
    queryKey: [queryKey, actualPage],
    queryFn: () => queryFn(actualPage, rowsPerPage),
    keepPreviousData: true,
    staleTime: 5000,
  });

  useEffect(() => {
    data
      ? setMaxRowsToShow(
          data[`max${queryKey.charAt(0).toUpperCase() + queryKey.slice(1)}`]
        )
      : setMaxRowsToShow(0);
  }, [data, setMaxRowsToShow]);

  useEffect(() => {
    refetch();
  }, [rowsPerPage, refetch]);

  return {
    actualPage,
    rowsPerPage,
    maxRowsToShow,
    data,
    refetch,
    isLoading,

    setActualPage,
    setRowsPerPage,
    setMaxRowsToShow,
  };
};
