import React, { useState, useMemo } from 'react';

import { CustomTable } from '../../../common/components';
import {
  IconButton,
  Typography,
  Box,
  Tooltip,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getAllInfluencers, deleteInfluencer } from '../../../services';
import { useTable } from '../../../hooks';

import { Influencer, ExtendedInfluencer } from '../../../interfaces';
import { InfluencerDetails } from './InfluencerDetails';
import '../../../index.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import { InfluencersEditModal } from './InfluencersEditModal';
//icons
import { Edit } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  influencer?: Influencer;
  setInfluencer: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
  onEditButtonClick: () => void;
  onExitEditMode: () => void;
  isEdited: boolean;
  searchResults?: any;
}

const MySwal = withReactContent(Swal);
export const InfluencersTable = ({
  setInfluencer,
  handleCellClick,
  onEditButtonClick,
  onExitEditMode,
  searchResults,
}: Props) => {
  const tableProps = useTable({
    queryKey: 'influencers',
    queryFn: getAllInfluencers,
  });

  //Para la busqueda
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const filteredInfluencers = useMemo(() => {
    if (!searchTerm) {
      return searchResults ? searchResults : tableProps.data?.influencers;
    }

    const lowercaseSearchTerm = searchTerm.toLowerCase();

    return (searchResults || tableProps.data?.influencers)?.filter(
      (influencer: Influencer) => {
        const influencerName = influencer.fullName?.toLowerCase();
        const influencerEmail = influencer.email?.toLowerCase();
        const influencerCategory = influencer.displayName?.toLowerCase();
        const matchesSearchTerm =
          (influencerName && influencerName.includes(lowercaseSearchTerm)) ||
          (influencerCategory &&
            influencerCategory.includes(lowercaseSearchTerm)) ||
          (influencerEmail && influencerEmail.includes(lowercaseSearchTerm));
        return matchesSearchTerm;
      }
    );
  }, [searchTerm, searchResults, tableProps.data?.influencers]);
  //Para abrir el mmodal de Detalles...
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [selectedInfluencers, setSelectedInfluencers] =
    useState<Influencer | null>(null);

  const handleInfluencersDetailsClick = (influencers: Influencer) => {
    setSelectedInfluencers(influencers);
    setIsDetailsVisible(true);
    handleOpen();
  };

  //Modal de Edición
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedInfluencer, setEditedInfluencer] =
    useState<ExtendedInfluencer | null>(null);
  const handleEditClick = (influencers: Influencer) => {
    setIsEditing(true);
    setEditedInfluencer({
      id: influencers.id,
      fullName: influencers.fullName,
      email: influencers.email,
      credits: influencers.credits,
      instagram: influencers.instagram,
      tiktok: influencers.tiktok,
      city: influencers.city,
      tiktokFollowers: influencers.tiktokFollowers,
      instagramFollowers: influencers.instagramFollowers,
      defaultCredits: influencers.defaultCredits,
      cancellations: influencers.cancellations,
    });
    openEditModal();
  };

  const handleDeleteClick = async (influencers: Influencer) => {
    try {
      await deleteInfluencer(influencers.id);
      tableProps.refetch();
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'El Influencer fue eliminado',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
    } catch (error) {
      console.error('Error al el comercio', error);
    }
  };

  const renderEditButtons = (row: Influencer) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        sx={iconButtonStyle}
        title="Editar"
        onClick={() => {
          onEditButtonClick();
          handleEditClick(row);
        }}
      >
        <Edit />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Ver más Información"
        onClick={() => handleInfluencersDetailsClick(row)}
      >
        <InfoOutlinedIcon />
      </IconButton>
      <IconButton
        sx={iconButtonStyle}
        title="Eliminar"
        onClick={() => handleDeleteClick(row)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );

  const renderCell =
    (property: keyof Influencer) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <>
            {property === 'fullName' && row.photoUrl && (
              <>
                <Avatar
                  src={row.photoUrl}
                  alt={row.fullName}
                  sx={{ marginRight: '8px', borderRadius: '50%' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                    {row[property]}
                  </Typography>
                  <Typography variant="caption">{row.email}</Typography>
                </Box>
              </>
            )}

            {property === 'fullName' && !row.photoUrl && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                  {row[property]}
                </Typography>
                <Typography variant="caption">{row.email}</Typography>
              </Box>
            )}

            {property !== 'fullName' && (
              <Typography>{row[property]} </Typography>
            )}
          </>
        </Box>
      );

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 125,
      headerClassName: 'table-header',
      renderCell: renderCell('fullName'),
    },

    /* {
      width: 125,
      field: "rate",
      headerName: "Rate %",
      align: "center",
      headerClassName: "table-header",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
        </>
      ),
    },*/

    {
      field: 'cancellations',
      headerName: 'Cancelaciones',
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('cancellations'),
    },
    {
      field: 'credits',
      headerName: 'Creditos',
      width: 125,
      align: 'center',
      headerClassName: 'table-header',
      renderCell: renderCell('credits'),
    } /*
    /*{
      field: "approve",
      width: 50,
      headerName: "",
      align: "right",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {row.verified === null && (
            <IconButton sx={iconButtonStyle}>
              <DoneOutlineOutlinedIcon />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: "reject",
      width: 50,
      headerName: "",
      align: "right",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {row.verified === null && (
            <IconButton sx={iconButtonStyle}>
              <CloseOutlinedIcon />
            </IconButton>
          )}
        </>
      ),
    },*/,
    {
      field: 'status',
      headerName: 'Verificado',
      width: 100,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          placement="left"
          title={row.verified ? 'Verificado' : 'No Verificado'}
        >
          {row.verified ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Tooltip>
      ),
    },

    {
      field: 'acctions',
      headerName: 'Acciones',
      width: 200,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams) => renderEditButtons(row),
    },
  ];
  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyles}>
            {isDetailsVisible && selectedInfluencers && (
              <InfluencerDetails
                influencerDetails={selectedInfluencers}
                closeEvent={handleClose}
              />
            )}
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={editModalOpen}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalEditStyles}>
            {isEditing && editedInfluencer && editedInfluencer.id && (
              <InfluencersEditModal
                influencerId={editedInfluencer.id}
                influencerEdit={editedInfluencer}
                closeEvent={closeEditModal}
              />
            )}
          </Box>
        </Modal>
      </div>
      <>
        <CustomTable
          title="Influencers"
          columns={columns}
          rows={filteredInfluencers}
          setActive={handleCellClick}
          {...tableProps}
        >
          <Stack
            spacing={2}
            width={'100%'}
            direction={'row'}
            justifyContent={'right'}
            marginBottom={2}
          >
            <form onSubmit={handleSearch}>
              <TextField
                type="text"
                placeholder="Buscar..."
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={searchStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Stack>
        </CustomTable>
      </>
    </>
  );
};
const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};

const ModalEditStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 650,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
const ModalStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 650,
  height: 630,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};

const searchStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '200px',
    '& fieldset': {
      border: '2px solid transparent',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF5722',
    },
  },
};
