import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Zoom,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  open: boolean;
  icon: JSX.Element;
  title: string;
  href: string;
  adminPriv?: string;
  isChildren?: boolean;
}

export const NavbarItem = ({
  open,
  icon,
  title,
  href,
  adminPriv,
  isChildren,
}: IProps) => {
  const role = 'user';
  if (adminPriv && role !== adminPriv) return <></>;
  return (
    <Tooltip
      title={title}
      placement="right"
      TransitionComponent={Zoom}
      arrow
      disableHoverListener={open && !isChildren}
    >
      <ListItem disablePadding sx={{ display: 'block' }}>
        <Link
          component={RouterLink}
          color="inherit"
          sx={{ textDecoration: 'none' }}
          to={href}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
                color: icon.props.sx.color,
                textDecoration: 'none',
              }}
            >
              {title}
            </ListItemText>
          </ListItemButton>
        </Link>
      </ListItem>
    </Tooltip>
  );
};
