import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getBusinessById } from '../../../services/api/business';
import { Business } from '../../../interfaces';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Instagram, Language } from '@mui/icons-material';
import {
  Avatar,
  Breadcrumbs,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Link as MuiLink,
} from '@mui/material';
import { TiktokIcon } from '../../Influencers/components';
import { ReturnButton } from '../components/ReturnButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';


 const capitalize = (str: string) => {
   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
 };



export const BusinessDetailsView: React.FC = () => {
  const { businessId } = useParams<{ businessId: string }>();
  const [business, setBusiness] = useState<Business | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const data = await getBusinessById(businessId!);
        setBusiness(data);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessDetails();
  }, [businessId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const socialLinks = [
    {
      name: 'Instagram',
      url: business?.instagram,
      icon: <Instagram sx={{ color: '#E4405F' }} />,
      title: 'Instagram',
    },
    {
      name: 'TikTok',
      url: business?.tiktok,
      icon: <TiktokIcon />,
      title: 'TikTok',
    },
    {
      name: 'Website',
      url: business?.website,
      icon: <Language sx={{ color: '#000000' }} />,
      title: 'Sitio Web',
    },
  ];

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    const formattedDate: string = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

 
  const membershipType = business?.membershipType
    ? capitalize(business.membershipType)
    : null;


  const cardContent1 = (
    <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        {/*Fecha de Creación: {formatDate(business.createdAt)}*/}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        País y Ciudad: {business?.country}, {business?.city}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Dirección: {business?.address}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Email: {business?.email}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Categoría: {business?.category}
      </Typography>
      <Typography
        variant="caption"
        display="flex"
        alignItems="center"
        style={{ marginLeft: '8px' }}
      >
        Verificado:
        <Tooltip
          placement="left"
          title={business?.verified ? 'Verificado' : 'No Verificado'}
        >
          {business?.verified ? (
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          ) : (
            <CloseIcon color="error" sx={{ ml: 1 }} />
          )}
        </Tooltip>
      </Typography>
      <Typography
        variant="caption"
        display="flex"
        alignItems="center"
        style={{ marginLeft: '8px' }}
      >
        Membresia:
        <Tooltip
          placement="left"
          title={business?.membershipActive ? 'Activa' : 'Inactiva'}
        >
          {business?.membershipActive ? (
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          ) : (
            <CloseIcon color="error" sx={{ ml: 1 }} />
          )}
        </Tooltip>
      </Typography>

      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Tipo de Membresia: {membershipType || 'Sin membresia'}
      </Typography>
    </Stack>
  );
  const CardContent2 = (
    <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
      <Typography variant="h6" gutterBottom>
        Contacto
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Teléfono: {business?.phoneNumber}
      </Typography>
      <Typography variant="caption" style={{ marginLeft: '8px' }}>
        Manager: {business?.managerFullName}-({business?.managerRole})
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Redes Sociales
      </Typography>
      {socialLinks.map((link) => (
        <Box key={link.name} display="flex" alignItems="center" mb={1}>
          <Box title={link.title} display="flex" alignItems="center">
            {link.icon}
          </Box>
          <Link to={link.url || ''} target="_blank" rel="noopener noreferrer">
            {link.url || 'Sin info'}
          </Link>
        </Box>
      ))}
    </Stack>
  );
  const cardContent3 = (
    <>
      <Stack sx={{ '> span': { fontSize: '16px', lineHeight: '20px' } }}>
        <Typography variant="h6" gutterBottom>
          Ofertas y Publicaciones
        </Typography>
        <Typography variant="caption" style={{ marginLeft: '8px' }}>
          Ofertas: {business?.amountOfOffers}
        </Typography>
        <Typography variant="caption" style={{ marginLeft: '8px' }}>
          Publicaciones por oferta: {business?.amountOfPublicationsPerOffer}
        </Typography>
        <Typography variant="caption" style={{ marginLeft: '8px' }}>
          Influencers por oferta: {business?.amountOfInfluencersPerOffer}
        </Typography>
        <Typography variant="caption" style={{ marginLeft: '8px' }}>
          Categoría: {business?.category}
        </Typography>
        <Typography variant="caption" style={{ marginLeft: '8px' }}>
          Costo de Creditos: {business?.creditCost}
        </Typography>
      </Stack>
    </>
  );

  const Title = () => (
    <Box width="54%" marginTop={5}>
      <Typography variant="h4">Detalles</Typography>
    </Box>
  );

  return (
    <>
      <Box sx={{ p: 2, width: '100%', minHeight: '100vh' }}>
        <Stack spacing={2} direction="column" width="75%" margin="0 auto">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="100%"
          >
            <Title />
            {/*<Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <MuiLink underline="hover" color="inherit" href="/home">
                Dashboard
              </MuiLink>
              <MuiLink underline="hover" color="inherit" href="/business">
                Comercios
              </MuiLink>
              <Typography color="textPrimary">{business?.name}</Typography>
            </Breadcrumbs>*/}
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: '#dddddd4a',
                  borderRadius: '15px',
                  width: '115%',
                  height: '98%',
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Box sx={{ padding: 2 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" fontWeight="900" fontSize="25px">
                      Información General
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      <Box
                        sx={{
                          ...iconBackButtonStyle,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ReturnButton />
                      </Box>
                    </Stack>
                  </Box>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                    pb={2}
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="column" flexGrow={1}>
                      <Typography variant="h6" fontWeight={900} fontSize="16px">
                        {business?.name} - Miembro Desde{' '}
                        {formatDate(business?.createdAt || '')}
                      </Typography>
                      <Typography variant="h6" fontWeight={700} fontSize="13px">
                        Usuario:{' '}
                        {business?.managerFullName
                          ? business?.managerFullName
                          : 'Sin info'}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {business?.description}
                      </Typography>
                    </Stack>
                    <Avatar
                      variant="rounded"
                      src={business?.photoUrl ?? undefined}
                      sx={{
                        width: { xs: '4rem', sm: '6rem' },
                        height: { xs: '4rem', sm: '6rem' },
                        marginLeft: { xs: 0, sm: 15 },
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                      }}
                    />
                  </Stack>
                  {cardContent1}
                  <Divider
                    variant="middle"
                    sx={{
                      backgroundColor: 'black',
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  />
                  {CardContent2}
                  <Divider
                    variant="middle"
                    sx={{
                      backgroundColor: 'black',
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  />
                  {cardContent3}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

const iconBackButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
};
