import { useState } from 'react';
import {
  MembershipsTable,
  MembershipModal,
} from '../Memberships/views';
import { useCRUDModal } from '../../hooks';
import { Route, Routes } from 'react-router-dom';
import { Box, Button} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from '@mui/material/Modal';

export const Memberships = () => {
  const [memberships, setMemberships] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: '/memberships',
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isEdited, setIsEdited] = useState(false);

  const handleCellClick = (event: any) => {
    const { row, field } = event;

    setMemberships(row);

    switch (field) {
      case 'approve':
        updateMutation.mutate({ ...row, verified: true });
        break;
      case 'reject':
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };

  const handleEditButtonClick = () => {
    setIsEdited(true);
  };

  const handleExitEditMode = () => {
    setIsEdited(false);
  };

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={createMembershipStyle}>
            <MembershipModal closeEvent={handleClose} />
          </Box>
        </Modal>
      </div>
      <Button
        aria-label="add"
        sx={iconButtonNewStyle}
        variant="contained"
        endIcon={<AddCircleIcon />}
        onClick={handleOpen}
      >
        Nuevo
      </Button>
      <Routes>
        <Route
          path="/"
          element={
            <MembershipsTable
              memberships={memberships}
              setMemberships={setMemberships}
              handleCellClick={handleCellClick}
              isEdited={isEdited}
              onEditButtonClick={handleEditButtonClick}
              onExitEditMode={handleExitEditMode}
            />
          }
        />
      </Routes>
    </>
  );
};

const iconButtonNewStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  color: 'black',
  width: '100px',
  height: '36px',
  flexShrink: 0,
  marginRight: 5,
  marginTop: 1,
  backgroundColor: '#E65022',
  '&:hover': {
    backgroundColor: '#E65022',
  },
  '&:focus': {
    backgroundColor: '#E65022',
  },
  fontWeight: 'bold',
  position: 'fixed',
  bottom: 16,
  right: 16,
};

const createMembershipStyle = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  maxWidth: '90vw',
  maxHeight: '90vh',
  width: 520,
  height: 630,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
};
