import { privateAxiosInstance } from '../axiosConfig';

import { Business } from '../../interfaces';

export const getAllBusiness = async (
  page = 0,
  BUSINESS_PER_PAGE = 0
): Promise<{ business: Business[]; maxBusiness: number }> => {
  const offset: number = BUSINESS_PER_PAGE * page!;
  const response = await privateAxiosInstance.get(
    `/business?limit=${BUSINESS_PER_PAGE}&offset=${offset}`
  );
  return response.data;
};

export const updateBusiness = async (datos: Partial<Business>) => {
  const {
    id,
    verified,
    createdAt,
    uid,
    displayName,
    managerRole,
    photoUrl,
    ...newValues
  } = datos;
  const response = await privateAxiosInstance.patch<Business>(
    `/business/${id}`,
    newValues
  );
  return response.data;
};

export const acceptOrRejectBusiness = async (
  businessId: string,
  options: { approved: boolean }
): Promise<any> => {
  const response = await privateAxiosInstance.get(
    `/business/approve-business/${businessId}?approved=${options.approved}`
  );
  return response.data;
};

export const deleteBusiness = async (id: string): Promise<any> => {
  const response = await privateAxiosInstance.delete(`/business/${id}`);
  return response.data;
};

export const getAllBusinessToApprove = async (
  page = 0,
  BUSINESS_PER_PAGE = 0
): Promise<{ business: Business[]; maxBusiness: number }> => {
  const offset: number = BUSINESS_PER_PAGE * page!;
  const response = await privateAxiosInstance.get(
    `/business/find-all/business-to-approve?limit=${BUSINESS_PER_PAGE}&offset=${offset}`
  );
  return response.data;
};

export const getBusinessById = async (id: string) => {
  const { data } = await privateAxiosInstance.get<Business>(`/business/${id}`);
  return data;
};
