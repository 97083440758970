import { Grid } from '@mui/material';
import pallete from '../../theme/pallete';
interface Props {
  children: JSX.Element | JSX.Element[];
}

export const drawerWidth = '64px';
export const navHeight = '56px';

export const DashboardLayout = ({ children }: Props) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: `calc(100vh - ${navHeight})`,
        backgroundColor: 'FFFFFF',
        padding: 0,
        marginTop: navHeight,
        position: 'relative',
        paddingLeft: drawerWidth,
      }}
    >
      {children}
    </Grid>
  );
};
