import { privateAxiosInstance } from "../axiosConfig";

import { ConfigLit } from "../../interfaces";

export const getAllConfig = async (): Promise<{ configlit: ConfigLit[] }> => {
  const response = await privateAxiosInstance.get(`/config`);
  return response.data;
};

export const updateConfig = async (datos: Partial<ConfigLit>) => {
  const { id, creditsPerRecomendation, ...newValues } = datos;
  const response = await privateAxiosInstance.patch<any>(
    `/config/${id}`,
    newValues
  );
  return response.data;
};
