import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Skeleton,
} from '@mui/material';

type StatCardProps = {
  title: string | number;
  subtitle: string;
  icon: React.ReactNode;
};

export const StatCard: React.FC<StatCardProps> = ({
  title,
  subtitle,
  icon,
}) => {
  return (
    <Grid item xs={12} sm={6} md={4} xl={4}>
      <Card sx={{ ...cardStyle }}>
        <CardContent>{icon}</CardContent>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {title ? (
            <Typography
              className="text-center animate__animated animate__fadeIn"
              variant="h5" 
            >
              {title}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} /> 
          )}
          <Typography className="text-center" variant="caption">
            {subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const cardStyle = {
  display: 'flex',
  height: { xs: '60px', sm: '80px', md: '100px', lg: '120px', xl: '140px' },
  borderRadius: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '10px',
  transition: 'all 0.2s ease-out',
  border: '1px solid transparent',
  '&:hover': {
    position: 'relative',
    transform: { xs: 'none', sm: 'scale(1.07)' },
    border: '1px solid #dee2e6',
  },
};
