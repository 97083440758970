import { Divider } from "@mui/material";

export const Terms = () => {
  return (
    <body
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        margin: 0,
      }}
    >
      <header
        style={{
          textAlign: "center",
          marginTop: "50px",
          padding: "30px",
        }}
      >
        <h1 style={{ textDecoration: "underline" }}>
          POLÍTICA DE PRIVACIDAD DE LIT APP
        </h1>
      </header>
      <div
        className="text-center"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Fecha de entrada en vigencia: 18/12/2023</h3>
        <p style={{ margin: "20px", maxWidth: "1000px" }}>
          Bienvenido/a a Lit, la aplicación que conecta negocios con influencers
          para potenciar su presencia en línea. En Lit, la privacidad y
          seguridad de tus datos son de suma importancia para nosotros. Por
          favor, toma un momento para revisar nuestra Política de Privacidad.
        </p>
      </div>
      <section
        style={{
          maxWidth: "1200px",
          width: "auto",
          margin: "auto",
          padding: "20px",
        }}
      >
        <p>
          <h2
            className="text-center"
            style={{
              fontWeight: "bold",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          >
            Información recopilada
          </h2>
        </p>
        <Divider color="grey" sx={{ marginBottom: "20px" }} />
        <p>
          <h3>Información del Usuario:</h3>
          Al registrarte en Lit, recopilamos tu nombre, dirección de correo
          electrónico y número de teléfono. Para brindarte una experiencia
          personalizada, podemos solicitar información adicional, como tus
          preferencias y intereses.
        </p>

        <p>
          <h3>Contenido Generado por el Usuario:</h3>
          Lit permite la publicación de contenido en forma de texto y fotos. La
          información que compartas estará asociada a tu cuenta y será visible
          para otros usuarios.
        </p>

        <p>
          <h3>Datos de Ubicación:</h3>
          Para facilitar la conexión entre negocios y influencers, recopilamos
          datos de ubicación para determinar la ubicación del negocio y el lugar
          de origen del influencer.
        </p>

        <p>
          <h3>Datos de Conexión:</h3>
          Registramos información sobre tus interacciones con la aplicación,
          incluyendo fechas y horas de acceso, vistas abiertas y acciones
          realizadas.
        </p>

        <p>
          <h2>Uso de la Información</h2>
        </p>

        <p>
          <h3>Conexión entre partes:</h3>
          Utilizamos la información del usuario para facilitar la conexión entre
          negocios y influencers, mejorando así la colaboración entre ambas
          partes.
        </p>

        <p>
          <h3>Personalización de Contenido:</h3>
          La información del usuario y las preferencias se utilizan para
          personalizar tu experiencia en Lit, mostrando contenido y sugerencias
          relevantes.
        </p>

        <p>
          <h3>Mejora de Servicios:</h3>
          Analizamos datos de uso de manera agregada y anónima para mejorar
          constantemente la funcionalidad y rendimiento de la aplicación.
        </p>

        <p>
          <h2>Compartir Información</h2>
        </p>
        <p>
          <h3>Con Influencers y Negocios:</h3>
          Compartiremos la información necesaria para facilitar la colaboración
          entre negocios e influencers, asegurando siempre la privacidad y
          consentimiento del usuario.
        </p>

        <p>
          <h3>Proveedores de Servicios:</h3>
          Podemos compartir información con proveedores de servicios que nos
          ayudan con funciones internas, como el alojamiento de datos y el
          análisis de datos.
        </p>

        <p>
          <h2>Seguridad y Retención de Datos</h2>
        </p>

        <p>
          <h3>Seguridad:</h3>
          Implementamos medidas de seguridad técnicas y organizativas para
          proteger tus datos contra accesos no autorizados o pérdidas.
        </p>

        <p>
          <h3>Retención de Datos:</h3>
          Conservamos tus datos mientras tu cuenta esté activa y durante un
          período razonable después de su desactivación. Puedes solicitar la
          eliminación de tus datos después de revisión por parte de nuestra
          compañía.
        </p>

        <p>
          <h2>Derechos del Usuario</h2>
        </p>

        <p>
          <h3>Acceso y Rectificación:</h3>
          Tienes derecho a acceder a tus datos personales y corregir cualquier
          información incorrecta.
        </p>

        <p>
          <h3>Eliminación de Datos:</h3>
          Puedes solicitar la eliminación de tus datos personales, sujeto a las
          disposiciones de retención mencionadas anteriormente.
        </p>

        <p>
          <h2>Cambios en la Política de Privacidad</h2>
        </p>
        <p>
          Nos reservamos el derecho de actualizar nuestra Política de Privacidad
          para reflejar cambios en nuestras prácticas de información. Te
          notificaremos sobre cualquier cambio significativo a través de la
          aplicación o por otros medios. Contacto Si tienes preguntas o
          inquietudes sobre nuestra Política de Privacidad, no dudes en ponerte
          en contacto con nosotros en{" "}
          <a
            className="aTerms"
            style={{
              color: "#007BFF",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            {" "}
            soporte@lit.com{" "}
          </a>
        </p>

        <p style={{ marginTop: "20px" }} className="text-center">
          Gracias por confiar en Lit. ¡Esperamos que disfrutes de tu experiencia
          en la aplicación!
        </p>
      </section>

      <footer
        style={{
          textAlign: "center",
          padding: "10px",
          marginTop: "auto",
        }}
      >
        &copy; {new Date().getFullYear()} Lit App. Todos los derechos
        reservados.
      </footer>
    </body>
  );
};
