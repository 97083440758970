import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomTable } from '../../../common/components';
import {
  IconButton,
  Typography,
  Box,
  Stack,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { getAllBusiness, deleteBusiness } from '../../../services/api/business';
import { useTable } from '../../../hooks';
import { Business, ExtendedBusinesss } from '../../../interfaces';
import { BusinesEditModal } from './BusinesEditModal';
import '../../../index.scss';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
//icons
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Edit } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  business?: Business;
  setBusiness: React.Dispatch<React.SetStateAction<any>>;
  handleCellClick: (event: any) => void;
  onEditButtonClick: () => void;
  onExitEditMode: () => void;
  isEdited: boolean;
  searchResults?: any;
}

const MySwal = withReactContent(Swal);
export const BusinessTable = ({
  handleCellClick,
  onEditButtonClick,
  searchResults,
}: Props) => {
  const tableProps = useTable({
    queryKey: 'business',
    queryFn: getAllBusiness,
  });

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const filteredBusines = useMemo(() => {
    if (!searchTerm) {
      return searchResults ? searchResults : tableProps.data?.business;
    }

    const lowercaseSearchTerm = searchTerm.toLowerCase();

    return (searchResults || tableProps.data?.business)?.filter(
      (busines: Business) => {
        const businesName = busines.name?.toLowerCase();
        const businesEmail = busines.email?.toLowerCase();
        const businesCategory = busines.category?.toLowerCase();
        const businesMembershipType = busines.membershipType?.toLowerCase();
        const matchesSearchTerm =
          (businesName && businesName.includes(lowercaseSearchTerm)) ||
          (businesCategory && businesCategory.includes(lowercaseSearchTerm)) ||
          (businesMembershipType &&
            businesMembershipType.includes(lowercaseSearchTerm)) ||
          (businesEmail && businesEmail.includes(lowercaseSearchTerm));
        return matchesSearchTerm;
      }
    );
  }, [searchTerm, searchResults, tableProps.data?.business]);


  //Modal de Edición
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedBusiness, setEditedBusiness] =
    useState<ExtendedBusinesss | null>(null);

  const handleEditClick = (business: Business) => {
    setIsEditing(true);
    setEditedBusiness({
      id: business.id,
      name: business.name,
      email: business.email,
      managerFullName: business.managerFullName,
      city: business.city,
      category: business.category,
      membershipType: business.membershipType,
      phoneNumber: business.phoneNumber,
      country: business.country,
      creditCost: business.creditCost,
      instagram: business.instagram,
      tiktok: business.tiktok,
      website: business.website,
      description: business.description,
      amountOfOffers: business.amountOfOffers,
      amountOfPublicationsPerOffer: business.amountOfPublicationsPerOffer,
      amountOfInfluencersPerOffer: business.amountOfInfluencersPerOffer,
    });
    openEditModal();
  };
  const handleDeleteClick = async (business: Business) => {
    try {
      await deleteBusiness(business.id);
      tableProps.refetch();
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'El comercio fue eliminado',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          popup: 'small-swal-popup',
          container: 'my-swal',
        },
      });
    } catch (error) {
      console.error('Error al el comercio', error);
    }
  };

  const renderEditButtons = (row: Business) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        sx={iconButtonStyle}
        title="Editar"
        onClick={() => {
          onEditButtonClick();
          handleEditClick(row);
        }}
      >
        <Edit />
      </IconButton>
      <Link
        to={`/business/details/${row.id}`}
        style={{ textDecoration: 'none' }}
      >
        <IconButton sx={iconButtonStyle} title="Ver más Información">
          <InfoOutlinedIcon />
        </IconButton>
      </Link>
      <IconButton
        sx={iconButtonStyle}
        title="Eliminar"
        onClick={() => handleDeleteClick(row)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );

  type MembershipType =
    | 'gold'
    | 'silver'
    | 'bronze'
    | 'free'
    | 'plata'
    | 'oro'
    | 'platino';

  const MembershipTypeStyles: Record<
    MembershipType,
    { backgroundColor: string; color: string }
  > = {
    gold: { backgroundColor: 'gold', color: 'darkgoldenrod' },
    silver: { backgroundColor: 'silver', color: 'darkgray' },
    bronze: { backgroundColor: 'peru', color: 'saddlebrown' },
    free: { backgroundColor: 'lightcoral', color: 'red' },
    plata: { backgroundColor: 'silver', color: 'darkgray' },
    oro: { backgroundColor: 'gold', color: 'darkgoldenrod' },
    platino: { backgroundColor: 'platinum', color: 'darkgray' },
  };

  const renderMembershipType = ({ value }: GridRenderCellParams) => {
    if (!value) {
      return null;
    }

    const normalizedValue = value.toLowerCase() as MembershipType;
    const style = MembershipTypeStyles[normalizedValue] || {};
    return (
      <Box
        sx={{
          ...style,
          padding: '4px 8px',
          borderRadius: '8px',
          textAlign: 'center',
          width: 'fit-content',
          margin: 'auto',
        }}
      >
        <Typography variant="body2">
          {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
        </Typography>
      </Box>
    );
  };

  const renderCell =
    (property: keyof Business) =>
    ({ row }: GridRenderCellParams) =>
      (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <>
            {property === 'name' && row.photoUrl && (
              <>
                <Avatar
                  src={row.photoUrl}
                  alt={row.fullName}
                  sx={{ marginRight: '8px', borderRadius: '50%' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                    {row[property]}
                  </Typography>
                  <Typography variant="caption">{row.email}</Typography>
                </Box>
              </>
            )}

            {property === 'name' && !row.photoUrl && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
                  {row[property]}
                </Typography>
                <Typography variant="caption">{row.email}</Typography>
              </Box>
            )}
            {property !== 'name' && <Typography>{row[property]} </Typography>}
          </>
        </Box>
      );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 125,
      headerClassName: 'table-header',
      renderCell: renderCell('name'),
    },
    {
      field: 'membershipType',
      headerName: 'Membresía',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderMembershipType,
    },
    /*
    {
      width: 150,
      field: "rate",
      headerName: "Rate %",
      align: "center",
      headerClassName: "table-header",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
          <StarOutlinedIcon sx={{ color: "#FF5A26B5" }} />
        </>
      ),
    },

    {
      field: 'works',
      headerName: 'Contrataciones',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams) => `6`,
    },*/
    {
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('category'),
    },
    {
      field: 'cancellations',
      headerName: 'Cancelaciones',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'table-header',
      renderCell: ({ row }: GridRenderCellParams) => `1/3`,
    },
    /* {
      field: 'calification',
      headerName: 'Calificación',
      flex: 1,
      minWidth: 125,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: renderCell('calification'),
    },*/

    {
      field: 'status',
      headerName: 'Estado',
      width: 100,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          placement="left"
          title={row.verified ? 'Verificado' : 'No Verificado'}
        >
          {row.verified ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </Tooltip>
      ),
    },

    {
      field: 'acctions',
      headerName: 'Acciones',
      width: 200,
      align: 'center',
      headerClassName: 'table-header',
      headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams) => renderEditButtons(row),
    },
  ];
  return (
    <>
      <div>
        <Modal
          open={editModalOpen}
          onClose={closeEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalEditStyles}>
            {isEditing && editedBusiness && editedBusiness.id && (
              <BusinesEditModal
                businesId={editedBusiness.id}
                businesEdit={editedBusiness}
                closeEvent={closeEditModal}
              />
            )}
          </Box>
        </Modal>
      </div>
      <CustomTable
        title="Comercios"
        columns={columns}
        rows={filteredBusines}
        setActive={handleCellClick}
        {...tableProps}
      >
        <Stack
          spacing={2}
          width={'100%'}
          direction={'row'}
          justifyContent={'right'}
          marginBottom={2}
        >
          <form onSubmit={handleSearch}>
            <TextField
              type="text"
              placeholder="Buscar..."
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={searchStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
      </CustomTable>
    </>
  );
};
const searchStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '200px',
    '& fieldset': {
      border: '2px solid transparent',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF5722',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FF5722',
    },
  },
};

const iconButtonStyle = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  borderRadius: '9px',
  background: '#FFF',
  width: '35px',
  height: '36px',
  flexShrink: 0,
  marginRight: 1,
};

const ModalEditStyles = {
  position: 'absolute' as const,
  top: '50%' as const,
  left: '50%' as const,
  transform: 'translate(-50%, -50%)',
  width: 650,
  height: 750,
  bgcolor: 'background.paper',
  p: 4,
  zIndex: 9999999,
  backgroundColor: 'white',
  flexShrink: '0',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center' as const,
  overflowY: 'auto',
};
