import React, { useState } from 'react';
import { updateBusiness } from '../../services/api/business';
import { BusinessTable } from './views/BusinessTable';
import { useCRUDModal } from '../../hooks';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { BusinessRegistrations } from './views/BusinessRegistrations';
import { Statistics } from '../Business/components/Statistics';

export const Business = () => {
  const [business, setBusiness] = useState<any>();
  const { updateMutation } = useCRUDModal({
    queryKey: 'business',
    updateFn: updateBusiness,
  });
  const [isEdited, setIsEdited] = useState(false);

  const location = useLocation();

  const handleCellClick = (event: any) => {
    const { row, field } = event;
    setBusiness(row);
    switch (field) {
      case 'approve':
        updateMutation.mutate({ ...row, verified: true });
        break;
      case 'reject':
        updateMutation.mutate({ ...row, verified: false });
        break;
    }
  };

  const handleEditButtonClick = () => {
    setIsEdited(true);
  };

  const handleExitEditMode = () => {
    setIsEdited(false);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <BusinessTable
                business={business}
                setBusiness={setBusiness}
                handleCellClick={handleCellClick}
                isEdited={isEdited}
                onEditButtonClick={handleEditButtonClick}
                onExitEditMode={handleExitEditMode}
              />
              <Statistics />
            </>
          }
        />
        <Route
          path="/registrations"
          element={
            <BusinessRegistrations
              setBusiness={setBusiness}
              handleCellClick={handleCellClick}
            />
          }
        />
        <Route path="/*" element={<Navigate to={'/bu'} />} />
      </Routes>
    </>
  );
};
